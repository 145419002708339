import React, { Component } from 'react';


export class Call extends Component {
    render() {
        return (
            <div className="main-menu-wrapper__call-number d-flex align-items-center">
            <div className="main-menu-wrapper__call-icon mr-10">
                <img src="images/chatting.png" alt="" />
            </div>
            <div className="main-menu-wrapper__call-text">
                <span>Contact Us</span>
                <h5><a href="tel:+91-7428212236">+91-742 821 2236</a></h5>
            </div>
        </div>

            )
        }
    }
    export default Call;