import React, { Component, Fragment } from 'react';
import { Navbar, Container, Row, Col, Button, Nav, NavDropdown } from 'react-bootstrap';
import { createBrowserHistory } from 'history';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Logo from './Logo';

export class Menu extends Component {
    render() {
        return (
            <Fragment>
                <Navbar expand="lg" className='main-navbar' id="mobile-menu">

                    <Col lg={5} md={5} sm={12} xs={12}><Navbar.Brand href="#home">  <Logo /></Navbar.Brand></Col>
                    <Col lg={7} md={7} sm={12} xs={12}>

                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav" className="main-menu menu-mobile">
                            <Nav className="ms-auto menu">
                                <ul>
                                    <li><a href={process.env.PUBLIC_URL + '/'}>Home</a></li>
                                    <li><a href={process.env.PUBLIC_URL + '/kazan-state-medical-university-russia'}>UNIVERSITY</a></li>
                                    <li className="menu-item-has-children"><a href="#">FACULTIES &amp; FEES</a>
                                        <ul className="sub-menu">
                                            <li><a href={process.env.PUBLIC_URL + '/admission-procedure'}>Adm Process</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/faculties-fees'}>Faculties & Fees</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/about-doctors-iea'}>Doctors-IEA</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/become-a-partner-associate-for-ksmu'}>Become a Partner</a></li>
                                            <li><a href={process.env.PUBLIC_URL + '/mbbs-in-russia-from-kazan-city'}>Kazan city</a></li>

                                        </ul>
                                    </li>                                
                                    <li><a href={process.env.PUBLIC_URL + '/ksmukazan-official-admission-partner'}>CONTACT US</a></li>
                                </ul>
                            </Nav>

                        </Navbar.Collapse></Col>
                </Navbar>
            </Fragment>


        )
    }
}
export default Menu;