import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import SideMenu from '../layout/SideMenu';
import Youtube from '../sliders/Youtube';


export class Doctors_ieas extends Component {
   render() {

      return (
         <main>


            <div className="page-title__area pt-110 a1">
               <div className="container">
                  <div className="row">
                     <div className="col-xxl-12">
                        <div className="page__title-wrapper text-center">
                           <h3 className="pb-100">Doctors-IEA’s Role and Function<br /> in Getting Admission in KSMU</h3>
                        </div>
                     </div>
                  </div>
               </div>
               <nav className="breadccrumb-bg">
                  <ul className="breadcrumb justify-content-center pt-20 pb-20">
                     <li className="bd-items"><a href={process.env.PUBLIC_URL + '/'}>Home</a></li>
                     <li className="bd-items bdritems">|</li>
                     <li className="bd-items"> <a href={process.env.PUBLIC_URL + '/admission'}> Doctors-IEA’s Role and Function in Getting Admission in KSMU</a></li>
                  </ul>
               </nav>
            </div>


            <div class="united-stats-area  ptb-100">
               <div class="container">
                  <div class="row">

                     <div class="col-xxl-12 col-xl-12 col-lg-12 col-sm-12">

                        <div class="sidebar__deatils">
                           <div class="united-states">
                              <h1>Doctors-IEA’s Role and Function<br /> in Getting Admission in KSMU</h1>
                              <p>The <strong>Doctors-IEA</strong> is the leading consultant for <strong>MBBS in Russia</strong>. We made admission to KSMU easier and within your reach. We feel honored to serve you honest and transparent service. Feel free to ask or enquire about anything. You can expect the best and honest guidance from us irrespective of the decision you take regarding <strong>MBBS admission to Kazan State Medical University</strong>.</p>
                              <h2><strong>Doctors-IEA’s Role and Function in Getting Admission in KSMU</strong></h2>
                              <p>Being an official and authorized representative for NMC recognized University for medical education in Russia we owe the responsibility to offer complete assistance and solution for your admission to <strong>Kazan State Medical University, Russia</strong>. Here is a brief detail of what will be our role and responsibility as a counselor and consultant:</p>
                              <ul className="necessary-link mb-40">
                                 <li><i className="fal fa-check-square"></i>Doctors-IEA team will hand-hold you throughout the admission procedure and even after that whenever you feel our need during your medical education in Russia at Kazan State Medical University.</li>
                                 <li><i className="fal fa-check-square"></i>We believe in making having cooperative and mutual relations with the aspiring candidate dreaming of pursuing MBBS in Russia. Every candidate who consults us becomes a part of our integral mission-admission.</li>
                                 <li><i className="fal fa-check-square"></i>When any interested candidate contacts us, our first move is to apply for admission at Kazan State Medical University on our behalf. We make sure you make it to the merit list of the KSMU.</li>
                                 <li><i className="fal fa-check-square"></i>The team of Doctors-IEA assists you with the documentation and Visa application as well as Embassy stamping and Ministry approval.</li>
                                 <li><i className="fal fa-check-square"></i>Our team also does all the work such as translating documents and notarization. So far we are maintaining our zero rejection of Visa approval.</li>
                                 <li><i className="fal fa-check-square"></i>We help you in getting reasonably affordable accommodation.</li>
                                 <li><i className="fal fa-check-square"></i>We arrange your travel essential and ticket to Russia, comprising travel insurance.</li>
                                 <li><i className="fal fa-check-square"></i>Our team also go one step ahead by providing a list of the best institute or training center that can guide you and teach you how to get guaranteed selection in NEXT exam (Replacement of FMGE or MCI screening test in 2024)</li>
                              </ul>

                              <h3>Why Choose Doctors-IEA for Kazan State Medical University Admission</h3>
                              <p>When you are planning to choose the best consultant for taking admission to <strong>Kazan State Medical University</strong>, you should not confuse at all by getting so many search results. The Doctors-IEA is the best in the business. We are the official representative of Kazan State Medical University, Russia. However, the question will arise in your mind why should you choose us and not others. Well, some of the suitable reasons are given below:</p>
                              <ul className="necessary-link mb-40">
                                 <li><i className="fal fa-check-square"></i>We are available 24x7x365 days in your service.</li>
                                 <li><i className="fal fa-check-square"></i>We are the only official representative for KSMU.</li>
                                 <li><i className="fal fa-check-square"></i>We are in this domain for years with an excellent reputation.</li>
                                 <li><i className="fal fa-check-square"></i>We are having a successful and unparalleled track record so far in MBBS admission in Russia.</li>
                                 <li><i className="fal fa-check-square"></i>We are dedicated to transparency and honest service.</li>
                                 <li><i className="fal fa-check-square"></i>We will provide assistance in travel arrangements, visa renewal, accommodation, etc.</li>
                                 <li><i className="fal fa-check-square"></i>Our team will be with you right from the beginning till admission and beyond.</li>
                                 <li><i className="fal fa-check-square"></i>Our team will also assist you in getting an education loan.</li>
                                 <li><i className="fal fa-check-square"></i>We have a strong tie and partnership with the Russian Medical University.</li>
                                 <li><i className="fal fa-check-square"></i>We offer students and parents the most suitable advice and consultancy.</li>
                              </ul>


                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <section className="popular-country-2 pt-200 pb-120 youtube-bg">
               <div className="container">
                  <div className="famous-countries">
                     <Youtube />
                  </div>
               </div>
            </section>

         </main>




      )
   }

}

export default Doctors_ieas;