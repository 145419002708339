import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import SideMenu from '../layout/SideMenu';
import Youtube from '../sliders/Youtube';
export class Kazan_state_medical_university_russia extends Component {
  render() {

    return (
      <main>
        <Helmet>
          <title>Get Direct Admission in MBBS in Kazan State Medical University Russia through Doctors ideal educational advisors</title>
          <meta name="description" content="Study MBBS in Kazan State Medical University Russia. Get direct admission through Doctors ideal educational advisors official partner of KSMU. Authorized official representative of Foreign Students in KazanSMU, Russia." />
          <link rel="canonical" href="https://studymbbsinkazan.com/kazan-state-medical-university-russia/" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="Get Direct Admission in MBBS in Kazan State Medical University Russia through Doctors ideal educational advisors" />
          <meta property="og:description" content="Study MBBS in Kazan State Medical University Russia. Get direct admission through Doctors ideal educational advisors official partner of KSMU. Authorized official representative of Foreign Students in KazanSMU, Russia." />
          <meta property="og:url" content="https://studymbbsinkazan.com/kazan-state-medical-university-russia/" />
          <meta property="og:site_name" content="Kazan State Med University" />
          <meta property="article:modified_time" content="2024-01-11T06:07:54+00:00" />
          <meta property="og:image" content="https://studymbbsinkazan.com/images/Kazan-State-Medical-University-Russia-e1584011048583.jpg" />
          <meta property="og:image:width" content="950" />
          <meta property="og:image:height" content="422" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:label1" content="Est. reading time" />
          <meta name="twitter:data1" content="5 minutes" />
        </Helmet>

        <div className="page-title__area pt-110 a1">
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="page__title-wrapper text-center">
                  <h3 className="pb-100">About University </h3>
                </div>
              </div>
            </div>
          </div>
          <nav className="breadccrumb-bg">
            <ul className="breadcrumb justify-content-center pt-20 pb-20">
              <li className="bd-items"><a href={process.env.PUBLIC_URL + '/'}>Home</a></li>
              <li className="bd-items bdritems">|</li>
              <li className="bd-items"> <a href={process.env.PUBLIC_URL + '/kazan-state-medical-university-russia'}> About University</a></li>
            </ul>
          </nav>
        </div>

        <div class="united-stats-area ptb-100">
          <div class="container">
            <div class="row">

              <div class="col-xxl-12 col-xl-12 col-lg-12 col-sm-12">

                <div class="sidebar__deatils">
                  <div class="united-states">

                    <div class="united-states__thumb mb-40">
                      <img src="images/KSMU-Kazan-Presented-by-Doctors-IEA-900x500.png" alt="" />
                    </div>
                    <h3>Administrative Building</h3>
                    <h1 class="united-states__title mb-15">  Kazan State Medical University, Russia  </h1>
                    <h3>Address: Ulitsa Butlerova, 49, Kazan, Republic of Tatarstan, Russia, 420012</h3>

                    <p><strong>Kazan State Medical University</strong> is a multifunctional and multi-leveled state institution of higher learning medicine. KSMU is located in the central part of Kazan. The main campus of KSMU is on Butlerov Street, although there are other KSMU buildings throughout Kazan city. Kazan state medical university is the third oldest medical university in Russia. With 206 years of experience in medical training, KSMU is justifiably an international University. Around 900 students from more than 57 countries around the world study here and half of them study in English. Moreover, we have a large group of students from the neighboring and remote regions of Russia. We are proud that Kazan medical school is attractive for young people from different parts of the world. That is why our priority is to support and care for international students. Kazan State Medical University students have access to electronic library resources, other modern means of communication during their studies. KSMU is listed in the top five Medical Universities of Russia. Kazan State Medical University graduates have all the necessary knowledge, skills, and qualifications that enable them to succeed in their future careers, they are in demand in the medical sector.</p>
                    <p>We are proud that Kazan Medical School is attractive for young people from different parts of the world. Therefore their priority is to be supportive and careful with international students. Education at Kazan Medical University is highly-rated we are in the top ten in the list of medical universities in the Russian Federation. Students consider KSMU as a desirable place to study and acknowledge our high-grade teaching. Choosing a university is one of the most important and challenging decisions of your life.</p>
                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 wow fadeInUp" data-wow-delay="0.3s">
                      <div className="faq-content faq-white">


                        <div className="accordion" id="accordionExample-1">
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne-1">
                              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne-1" aria-expanded="true" aria-controls="collapseOne-1">
                                KSMU Today
                              </button>
                            </h2>
                            <div id="collapseOne-1" className="accordion-collapse collapse show" aria-labelledby="headingOne-1" data-bs-parent="#accordionExample-1">
                              <div className="accordion-body">
                                <ul class="necessary-link mb-40">
                                  <li><i class="fal fa-check-square"></i>First University in Russia to be accredited by the World Federation for Medical Education (WFME).</li>
                                  <li><i class="fal fa-check-square"></i>More than 6000 medical students,.</li>
                                  <li><i class="fal fa-check-square"></i>Around 1600 students &amp; Residents representing more than 50 countries study in KSMU.</li>
                                  <li><i class="fal fa-check-square"></i>7 Specialties &amp; 2 Bachelor Programs.</li>
                                  <li><i class="fal fa-check-square"></i>44 Clinical Residency &amp; 39 PhD Program.</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo-1">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo-1" aria-expanded="false" aria-controls="collapseTwo-1">
                                Duration of Studying MBBS in KaznSMU, Russia
                              </button>
                            </h2>
                            <div id="collapseTwo-1" className="accordion-collapse collapse" aria-labelledby="headingTwo-1" data-bs-parent="#accordionExample-1">
                              <div className="accordion-body">
                                <ul class="necessary-link mb-40">
                                  <li><i class="fal fa-check-square"></i>Duration of MBBS Course is <strong>5.8 years for English Medium </strong>and 7 years for Russian Medium.</li>
                                  <li><i class="fal fa-check-square"></i>BDS (Dentistry) 5 years English Medium</li>
                                  <li><i class="fal fa-check-square"></i>Pharmacy 5 years English Medium.</li>
                                </ul>

                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree-1">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree-1" aria-expanded="false" aria-controls="collapseThree-1">
                                KazanSMU Admission:
                              </button>
                            </h2>
                            <div id="collapseThree-1" className="accordion-collapse collapse" aria-labelledby="headingThree-1" data-bs-parent="#accordionExample-1">
                              <div className="accordion-body">
                                <ul class="necessary-link mb-40">
                                  <li><i class="fal fa-check-square"></i> one intake in the year June – September.</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree1-1">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree1-1" aria-expanded="false" aria-controls="collapseThree1-1">
                                KSMU Faculties:
                              </button>
                            </h2>
                            <div id="collapseThree1-1" className="accordion-collapse collapse" aria-labelledby="headingThree1-1" data-bs-parent="#accordionExample-1">
                              <div className="accordion-body">
                                <ul class="necessary-link mb-40">
                                  <li><i class="fal fa-check-square"></i> Faculty of General Medicine</li>
                                  <li><i class="fal fa-check-square"></i> Faculty of Pediatrics</li>
                                  <li><i class="fal fa-check-square"></i> Faculty of Dentistry</li>
                                  <li><i class="fal fa-check-square"></i> Faculty of Pharmacy</li>
                                  <li><i class="fal fa-check-square"></i> Faculty of Preventive Medicine</li>
                                  <li><i class="fal fa-check-square"></i> Faculty of Nursing</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree2-1">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree2-1" aria-expanded="false" aria-controls="collapseThree2-1">
                                Remarkable dates of KSMU, Russia
                              </button>
                            </h2>
                            <div id="collapseThree2-1" className="accordion-collapse collapse" aria-labelledby="headingThree2-1" data-bs-parent="#accordionExample-1">
                              <div className="accordion-body">
                                <ul class="necessary-link mb-40">
                                  <li><i class="fal fa-check-square"></i>5th November (according to the old calendar) 1804 Emperor Alexander I declares in his royal command to establish a University in the city of Kazan</li>
                                  <li><i class="fal fa-check-square"></i>14th of May (2nd of May according to the old calendar) 1814 — Establishment of the « <strong>Department of Doctoral or Medical sciences </strong>» of Kazan University</li>
                                  <li><i class="fal fa-check-square"></i>1837 — The Anatomy Theatre was built</li>
                                  <li><i class="fal fa-check-square"></i>1840 — The University Clinic was opened</li>
                                  <li><i class="fal fa-check-square"></i>1858 — The Physiology Laboratory is launched</li>
                                  <li><i class="fal fa-check-square"></i>1893 — The first editions of «The Neurology Newsletter» are published</li>
                                  <li><i class="fal fa-check-square"></i>1900 — The complex of University Clinics starts functioning; the Bacteriological Institute is established on the basis of the Medical Faculty</li>
                                  <li><i class="fal fa-check-square"></i>1901 — The first editions of «Kazan Medical Journal» are published</li>
                                  <li><i class="fal fa-check-square"></i>5th November 1930 — the Medical Faculty of Kazan State University is reorganized into Kazan State Medical Institute</li>
                                  <li><i class="fal fa-check-square"></i>1930 — Establishment of the Faculty of Prophylactic Medicine (Sanitary Medicine)</li>
                                  <li><i class="fal fa-check-square"></i>1932 — The Pediatric Faculty is created</li>
                                  <li><i class="fal fa-check-square"></i>1954 — Establishment of the Dental Faculty</li>
                                  <li><i class="fal fa-check-square"></i>1975 — Training at the Pharmaceutical Faculty is launched</li>
                                  <li><i class="fal fa-check-square"></i>1991 — The Faculty of Social Work is established</li>
                                  <li><i class="fal fa-check-square"></i>1994 — The Faculty of Management and Higher Nursing Education starts functioning</li>
                                  <li><i class="fal fa-check-square"></i>29th of April 1994 — Kazan State Medical Institute is reorganized and acquires the status of Kazan State Medical University</li>
                                  <li><i class="fal fa-check-square"></i>14th May 2014 — 200th Jubilee.</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree3-1">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree3-1" aria-expanded="false" aria-controls="collapseThree3-1">
                                KSMU Recognition
                              </button>
                            </h2>
                            <div id="collapseThree3-1" className="accordion-collapse collapse" aria-labelledby="headingThree3-1" data-bs-parent="#accordionExample-1">
                              <div className="accordion-body">
                                <p>Kazan state medical university is recognized by the Ministry of Health and Ministry of Education of the Russian Federation. KSMU is a government-funded university. KSMU is recognized by NMC, WHO, ECFMG (USA), AMC, GMC, etc.</p>
                              </div>
                            </div>
                          </div>


                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree4-1">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree4-1" aria-expanded="false" aria-controls="collapseThree4-1">
                                KSMU Hostels
                              </button>
                            </h2>
                            <div id="collapseThree4-1" className="accordion-collapse collapse" aria-labelledby="headingThree4-1" data-bs-parent="#accordionExample-1">
                              <div className="accordion-body">
                                <p>Kazan State Medical University has seven hostels where foreign students can live throughout their studies. Students are offered different kinds of rooms which may differ in size. As a rule; two or three students can share the room. All rooms are comfortable, well heated, and fully furnished according to student's needs.</p>
                              </div>
                            </div>
                          </div>


                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree5-1">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree5-1" aria-expanded="false" aria-controls="collapseThree5-1">
                                Eligibility Criteria for study MBBS in KSMU, Russia
                              </button>
                            </h2>
                            <div id="collapseThree5-1" className="accordion-collapse collapse" aria-labelledby="headingThree5-1" data-bs-parent="#accordionExample-1">
                              <div className="accordion-body">
                                <ul class="necessary-link mb-40">
                                  <li><i class="fal fa-check-square"></i> The candidate must have completed 17 years on or before 31 December at the time of admission for the MBBS Course.  </li>
                                  <li><i class="fal fa-check-square"></i> The students must have secured 65% marks in each subject's ECB (English, Chemistry, Biology) in their 12th or higher secondary done through from CBSE/State Board or any other equivalent board.</li>
                                  <li><i class="fal fa-check-square"></i> The candidate's NEET score is mandatory for admission.</li>
                                </ul>
                              </div>
                            </div>
                          </div>


                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree6-1">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree6-1" aria-expanded="false" aria-controls="collapseThree6-1">
                                Documents required for Admission in KSMU, Russia
                              </button>
                            </h2>
                            <div id="collapseThree6-1" className="accordion-collapse collapse" aria-labelledby="headingThree6-1" data-bs-parent="#accordionExample-1">
                              <div className="accordion-body">
                                <ul class="necessary-link mb-40">
                                  <li><i class="fal fa-check-square"></i> Online Application form</li>
                                  <li><i class="fal fa-check-square"></i> Neet eligibility certificate</li>
                                  <li><i class="fal fa-check-square"></i> 10th and 12th class mark sheets</li>
                                  <li><i class="fal fa-check-square"></i> Passport</li>
                                  <li><i class="fal fa-check-square"></i> 20 passport size Photos (35*45 mm size with white background at the matte paper)</li>
                                </ul>
                              </div>
                            </div>
                          </div>


                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="popular-country-2 pt-200 pb-120 youtube-bg">
          <div className="container">
            <div className="famous-countries">
              <Youtube />
            </div>
          </div>
        </section>

        <div class="contact__area bg-orange">
          <div class="contact__vmap">
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6998.490889078664!2d77.15903!3d28.71221!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x18541e282325b6d7!2sMBBS%20in%20Russia%20%7C%20Study%20MBBS%20Abroad%20Russia%20%7C%20Doctors-IEA%20(Top%20Consultants%20for%20Russia)!5e0!3m2!1sen!2sin!4v1644436382447!5m2!1sen!2sin"></iframe>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-xxl-6 col-xl-6 col-lg-6"></div>
              <div class="col-xl-6 col-xl-6 col-lg-6">
                <div class="contact__form pt-110 pb-110 ">
                  <h3>Our Official Admission Partner of KSMU: Doctors-IEA Pvt Ltd.</h3>

                  <h4><strong>For more information regarding university, admission procedure, the fee structure of all faculties, contact our official representative of Indian &amp; International Students (Asia, Africa, Arab Countries); Doctors-Ideal Educational Advisors</strong></h4>
                  <ul class="necessary-link mb-40">
                    <li><i class="fal fa-check-square"></i>Official Contact Details; For Indian Students: 1800-419-5827 (Toll-free) | For Other Countries: <a href="tel:+7-951-517-45-07">+7-951-517-45-07</a></li>
                    <li><i class="fal fa-check-square"></i>Emails: <a href="mailto:contact@thedoctorsiea.in">contact@thedoctorsiea.in</a>, <a href="mailto:thedoctors.iea@gmail.com">thedoctors.iea@gmail.com</a></li>
                    <li><i class="fal fa-check-square"></i>Websites: <a href="https://www.thedoctorsiea.in/" target="_blank">www.thedoctorsiea.in</a>,  <a href="https://www.studymbbsinkazan.com/" target="_blank">www.studymbbsinkazan.com</a></li>
                    <li><i class="fal fa-check-square"></i>WhatsApp No.’s: <a href="https://wa.me/917428212236">+91-7428212236 (Ind)</a></li>
                  </ul>

                  <h4>''<strong>Admission open for 2024-25 Academic Year''</strong></h4>


                </div>
              </div>
            </div>
          </div>
        </div>

      </main>




    )
  }

}

export default Kazan_state_medical_university_russia;