import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import SideMenu from '../layout/SideMenu';
import Youtube from '../sliders/Youtube';


export class About_ksmu extends Component {
    render() {

        return (       
           <main>
               

    <div className="page-title__area pt-110 a1">
    <div className="container">
        <div className="row">
           <div className="col-xxl-12">
              <div className="page__title-wrapper text-center">
                 <h3 className="pb-100">About KSMU </h3>
              </div>
           </div>
        </div>
     </div>
     <nav className="breadccrumb-bg">
        <ul className="breadcrumb justify-content-center pt-20 pb-20">
           <li className="bd-items"><a href={process.env.PUBLIC_URL + '/'}>Home</a></li>
           <li className="bd-items bdritems">|</li>
           <li className="bd-items"> <a href={process.env.PUBLIC_URL + '/about-ksmu'}> About KSMU</a></li>
        </ul>
     </nav>
</div>


<div class="united-stats-area  ptb-100">
    <div class="container">
        <div class="row">
        
        <div class="col-xxl-12 col-xl-12 col-lg-12 col-sm-12">

            <div class="sidebar__deatils">
                    <div class="united-states">

                        <div className='row'>
                            <div className='col-sm-6'><iframe width="100%" height="315" src="https://www.youtube.com/embed/CDVD8lBW4-s" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
                            <div className='col-sm-6'><img src='images/Kazan-State-Medical-University.jpg' alt='' /></div>
                        </div>
                <h1>About KSMU</h1>

                <p>Undoubtedly, Kazan State Medical University is one of the most popular and leading universities for medical education. It is contemplated as one of the top universities of Russia. It is a multifunctional and multi-leveled government institution for higher learning in medicine that comes under the System of Higher Education and Scientific Research of the Ministry of Health in Russia. It is immensely appreciated for its core values and integrated medical education. It is located in the central part of Kazan city. There are 9 faculties and 65 medicine departments in this university. It has more than 5500 medical students including interns and clinical residents. KSMU is the 3 rd rank university among the top 10 list of Russian medical universities. The Kazan state medical university country ranking is 124. Moreover, the international ranking of this university is also appreciable. It has more than 200 years of experience in medical education as it was established in 1814 and truly an international university in Russia. Kazan State Medical University’s official website provides ample information to the students seeking admission in medical education courses and programs.</p>
<h4>The only intake or the admission process in KSMU follows from June to October.</h4>
<p>The university offers world-class medical education to students who come from more than 57 countries in the world including India. The Kazan State Medical University admission is near about 40- 45 percent. The selection policy of KSMU is based on the entrance examination and students’ past academic records and grades. KSMU imparts an array of academic and non-academic services and facilities that may include the Kazan State Medical University hostel, library, sports amenities, and administrative services as well as study abroad and exchange programs.</p>
                
<h2>Sophisticated Infrastructure</h2>
            <p>The infrastructure of KSMU is quite excellent. The entire campus is remarkably maintained and truly equipped with modern and advanced instruments as well as laboratory machinery. Hence seeking admission to KSMU is highly beneficial for students’ careers because not only in bestow world-class medical education but also recognized approved by WHO, NMC of India, ECFMG,  and the European Council. KSMU degree is widely acknowledged and accepted around the globe. It offers medical courses and programs leading to officially recognized higher education degree comprises bachelor’s degrees, master’s degrees, and doctorate degrees.</p>
            <h3>The Faculty at KSMU</h3>
           <ul className="necessary-link mb-40">
              <li><i className="fal fa-check-square"></i>Faculty of General Medicine</li>
              <li><i className="fal fa-check-square"></i>Faculty of Preventive Medicine</li>
              <li><i className="fal fa-check-square"></i>Faculty of Pediatrics</li>
              <li><i className="fal fa-check-square"></i>Faculty of Dentistry</li>
              <li><i className="fal fa-check-square"></i>Faculty of Pharmacy</li>
              <li><i className="fal fa-check-square"></i>Faculty of Nursing Education</li>
              <li><i className="fal fa-check-square"></i>Faculty of Social Work</li>
              <li><i className="fal fa-check-square"></i>Preparatory Faculty</li>
            </ul>
          
            <h3>Kazan State Medical University ranking</h3>
            <p>If you are the one who focuses and emphasizes too much on ranking then you will be glad to know that Kazan State medical University’s ranking is quite encouraging and appreciable. It is ranked 4489 by the Web metrics Ranking of World Universities. However, the world ranking for KSMU is 6381 in the 4icu world university ranking and 4489 in the Webometrics Ranking of World University. However, the current Kazan State medical University country ranking is 124.</p>
            <h3>Key Statistics of KSMU</h3>
            <p>KSMU is Co-education University where both males and females can apply for admission in medical education. The student ratio of males to females is 64:36. Moreover, the number of FTE students is as high as around 5500. As far as the number of international students is concerned, it is almost 19% which makes it 5:1 which extremely good. The university has more than 1500 hundred staff which turns out to be 7.4 no. of staff per student. Such statistics speak both the volume and balance between staff, administration, and students which quite favorable and healthy for a successful institution. For this reason only, Kazan State Medical University’s reviews are quite excellent.</p>
            <h3>Duration of MBBS study in KSMU</h3>
            <p>The duration of completing MBBS in KSMU is 6 years for English medium students. It comprises 5 years of classroom learning and another 1 year of apprenticeship.</p>
                    </div>
                    </div>
                </div>
            </div>
            </div>
            </div>
            <section className="popular-country-2 pt-200 pb-120 youtube-bg">
    <div className="container">
	<div className="famous-countries">
		<Youtube />
		</div>
	</div>
</section>

           </main>
          



 )
}

}

export default About_ksmu;