import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import SideMenu from '../layout/SideMenu';
import Youtube from '../sliders/Youtube';


export class About_doctors_iea extends Component {
   render() {

      return (
         <main>
            <Helmet>
               <title>Doctors-IEA Team | The Official Representative of Kazan State Med University</title>
               <meta name="description" content="Doctors-Ideal educational Advisors is the official representative of Kazan state medical university, Russia. For more info call 1800-419-5827" />
               <link rel="canonical" href="https://studymbbsinkazan.com/about-doctors-iea/" />
               <meta property="og:locale" content="en_US" />
               <meta property="og:type" content="article" />
               <meta property="og:title" content="Doctors-IEA Team | The Official Representative of Kazan State Med University" />
               <meta property="og:description" content="Doctors-Ideal educational Advisors is the official representative of Kazan state medical university, Russia. For more info call 1800-419-5827" />
               <meta property="og:url" content="https://studymbbsinkazan.com/about-doctors-iea/" />
               <meta property="og:site_name" content="Kazan State Med University" />
               <meta property="article:modified_time" content="2024-01-11T06:16:57+00:00" />
               <meta property="og:image:width" content="950" />
               <meta property="og:image:height" content="450" />
               <meta name="twitter:card" content="summary_large_image" />
               <meta name="twitter:label1" content="Est. reading time" />
               <meta name="twitter:data1" content="2 minutes"></meta>
            </Helmet>

            <div className="page-title__area pt-110 a1">
               <div className="container">
                  <div className="row">
                     <div className="col-xxl-12">
                        <div className="page__title-wrapper text-center">
                           <h3 className="pb-100">About Us </h3>
                        </div>
                     </div>
                  </div>
               </div>
               <nav className="breadccrumb-bg">
                  <ul className="breadcrumb justify-content-center pt-20 pb-20">
                     <li className="bd-items"><a href={process.env.PUBLIC_URL + '/'}>Home</a></li>
                     <li className="bd-items bdritems">|</li>
                     <li className="bd-items"> <a href={process.env.PUBLIC_URL + '/about_doctors_iea'}> About Us</a></li>
                  </ul>
               </nav>
            </div>


            <div class="united-stats-area  ptb-100">
               <div class="container">
                  <div class="row">

                     <div class="col-xxl-12 col-xl-12 col-lg-12 col-sm-12">

                        <div class="sidebar__deatils">
                           <div class="united-states">


                              <p><strong>Doctors-IEA has guided a number of students for Medical studies at Kazan State Medical University since 2011. Many of them completed their degree / degree+post graduations and practicing in India &amp; Abroad. Doctors-IEA director’s one of the graduates of Russian Medical University, completed post-graduation also and helping students in saving valuable time and money with his guidance for study medicine at Kazan State Medical University. We are not agents, but a group of qualified doctors who are trying to facilitate you in achieving your goal.</strong></p>
                              <h4><strong>Some reasons which make Doctors-IEA Most trusted educational consultants for Russia.</strong></h4>
                              <ul class="necessary-link mb-40">
                                 <li><i class="fal fa-check-square"></i>Doctors-IEA has the full experience to provide professional consulting services for students who plan to study Medical in KazanSMU, Russia.</li>
                                 <li><i class="fal fa-check-square"></i>Doctors-EIA has close contact with KazanSMU for the course information, application requirements, and procedures. Our team is fully ready to handle student applications effectively and efficiently.</li>
                                 <li><i class="fal fa-check-square"></i>Doctors-IEA sincerely cares for our student’s future and is committed to providing reliable information and keeping to our promise.</li>
                                 <li><i class="fal fa-check-square"></i>Doctors-IEA Team is well recognized for its reliability. For its professional service and teams of experienced staff in the representative offices, Doctors-IEA Team is highly regarded within the industry by universities, parents, and students alike.</li>
                                 <li><i class="fal fa-check-square"></i>Doctors-IEA never hides any facts and truths.</li>
                                 <li><i class="fal fa-check-square"></i>Doctors-IEA never charges any hidden expenses.</li>
                                 <li><i class="fal fa-check-square"></i>Doctors-IEA has an excellent student-parent support system.</li>
                                 <li><i class="fal fa-check-square"></i>Doctors-IEA assures financial &amp; educational assistance without any hurdle.</li>
                                 <li><i class="fal fa-check-square"></i>Doctors-IEA Team will assist in the whole process of admission including your visa and travel.</li>
                              </ul>
                              <p><strong>Official Contact Details; For Indian Students: 1800-419-5827 (Toll-free) | For Other Countries: <a href='tel:+7-951-517-45-07'>+7-951-517-45-07</a></strong></p>


                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <section className="popular-country-2 pt-200 pb-120 youtube-bg">
               <div className="container">
                  <div className="famous-countries">
                     <Youtube />
                  </div>
               </div>
            </section>

         </main>




      )
   }

}

export default About_doctors_iea;