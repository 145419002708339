import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import SideMenu from '../layout/SideMenu';
import Youtube from '../sliders/Youtube';


export class Indian_mess extends Component {
    render() {

        return (       
           <main>
               

    <div className="page-title__area pt-110 a1">
    <div className="container">
        <div className="row">
           <div className="col-xxl-12">
              <div className="page__title-wrapper text-center">
                 <h3 className="pb-100">Indian food availability in city</h3>
              </div>
           </div>
        </div>
     </div>
     <nav className="breadccrumb-bg">
        <ul className="breadcrumb justify-content-center pt-20 pb-20">
           <li className="bd-items"><a href={process.env.PUBLIC_URL + '/'}>Home</a></li>
           <li className="bd-items bdritems">|</li>
           <li className="bd-items"> <a href={process.env.PUBLIC_URL + '/admission'}> Indian food availability in city</a></li>
        </ul>
     </nav>
</div>


<div class="united-stats-area  ptb-100">
    <div class="container">
        <div class="row">
       
        <div class="col-xxl-12 col-xl-12 col-lg-12 col-sm-12">
            <div class="sidebar__deatils">
                    <div class="united-states">

                    <p><strong>Dear aspirants,</strong></p>
<p>as per now, their is no Indian mess available in university or nearby. <br/>Yes, students can cook ownself as kitchens are available in both government or private hostels.<br/> And they can visit Indian and Russian restaurants for the same.</p>


          
      
                    </div>
                    </div>
                </div>
            </div>
            </div>
            </div>
            <section className="popular-country-2 pt-200 pb-120 youtube-bg">
    <div className="container">
	<div className="famous-countries">
		<Youtube />
		</div>
	</div>
</section>

           </main>
          



 )
}

}

export default Indian_mess;