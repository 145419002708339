import React, { Component } from 'react';
import { Upcoming } from './layout/Upcoming';
import { Facebook } from './layout/Facebook';
import { Sliders } from './sliders/Sliders';
import Youtube from './sliders/Youtube';
import Clients from './sliders/Clients';
import Faq from './layout/Faq';
import { Helmet } from "react-helmet";




export class Home extends Component {

    render() {
        return (
            <main>
                <Helmet>
                    <title>Kazan State Medical University Official Representative | Admissions are open for 2024</title>
                    <meta name="description" content="Kazan State Medical University - About KSMU, Faculty at KSMU, Ranking, Duration of MBBS, Admission 2024, Admission Procedure, Eligibility, and Process, Hostel, Accreditation, Advantages do Students, Fees Structure" />
                    <link rel="canonical" href="https://studymbbsinkazan.com/" />
                    <meta property="og:locale" content="en_US" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="Kazan State Medical University Official Representative | Admissions are open for 2024" />
                    <meta property="og:description" content="Kazan State Medical University - About KSMU, Faculty at KSMU, Ranking, Duration of MBBS, Admission 2024, Admission Procedure, Eligibility, and Process, Hostel, Accreditation, Advantages do Students, Fees Structure" />
                    <meta property="og:url" content="https://studymbbsinkazan.com/" />
                    <meta property="og:site_name" content="Kazan State Med University" />
                    <meta property="article:modified_time" content="2024-01-11T06:01:57+00:00" />
                    <meta property="og:image" content="https://studymbbsinkazan.com/KSMU-main-building.jpg" />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:label1" content="Est. reading time" />
                    <meta name="twitter:data1" content="15 minutes" />
                </Helmet>
                <section className="slider-area fix">
                    <div className="slider-active swiper-container">
                        <div className="swiper-wrapper">
                            <Sliders />
                        </div>
                        <div className="swiper-button-prev slide-prev"><i className="far fa-long-arrow-left"></i></div>
                        <div className="swiper-button-next slide-next"><i className="far fa-long-arrow-right"></i></div>
                    </div>
                </section>
                <section className="visa-area ">
                    <div className="container">
                        <div className="row g-0 theme-bg visa-top">
                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                                <div className="visa__items br-none">
                                    <div className="visa__items-single d-flex align-items-center">
                                        <div className="visa__items-single-icon">
                                            <i className="flaticon-admission"></i>
                                        </div>
                                        <h4 className="visa__items-single-title">
                                            <a href={process.env.PUBLIC_URL + '/kazan-state-medical-university-russia'}> University</a>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                                <div className="visa__items">
                                    <div className="visa__items-single d-flex align-items-center">
                                        <div className="visa__items-single-icon">
                                            <i className="flaticon-content"></i>
                                        </div>
                                        <h4 className="visa__items-single-title">
                                            <a href={process.env.PUBLIC_URL + '/faculties-fees'}> Faculties &amp; Fees </a>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                                <div className="visa__items">
                                    <div className="visa__items-single d-flex align-items-center">
                                        <div className="visa__items-single-icon">
                                            <i className="flaticon-customer"></i>
                                        </div>
                                        <h4 className="visa__items-single-title">
                                            <a href={process.env.PUBLIC_URL + '/become-a-partner-associate-for-ksmu'}>Become A Partner</a>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                                <div className="visa__items">
                                    <div className="visa__items-single d-flex align-items-center">
                                        <div className="visa__items-single-icon">
                                            <i className="flaticon-passport"></i>
                                        </div>
                                        <h4 className="visa__items-single-title">
                                            <a href={process.env.PUBLIC_URL + '/mbbs-in-russia-from-kazan-city'}>Kazan City</a>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="about-area-2 pt-90 pb-85 wow fadeInUp" data-wow-delay="0.3s" >
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-6 col-xl-6 col-lg-6 mb-30">
                                <div className="about2-left d-flex">
                                    <div className="about2-left__img1 mr-10">
                                        <img src="images/about2.jpg" alt="" />
                                    </div>
                                    <div className="about2-left__img2">
                                        <img src="images/about2-1.jpg" alt="" />

                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-6 col-xl-6 col-lg-6 mb-30">
                                <div className="section_title_wrapper">

                                    <h2 className="section-title about-span mb-30">
                                        <span>  Official Admission</span><br />  Partner Of Kazan State Medical University  for Indians &amp; Foreign Students
                                    </h2>
                                    <div class="section_title_wrapper__about-content mb-40">
                                        <p>Doctors-IEA Pvt. Ltd (Doctors-Ideal Educational Advisors) is the Authorized Official Representative of Indians and Foreign Students in KazanSMU, Russia. We are the official admission partner of KSMU from last 10 years. Doctors-IEA is well recognized for it's reliability. We sincerely cares for our students future. We are committed to provide reliable information and keeping to our promise. </p>
                                        <h3>Welcome to KSMU!</h3>

                                    </div>
                                </div>
                                <div className="about-trust">
                                    <div className="row">
                                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                                            <div className="about2__item d-flex  mb-20 mr-20">
                                                <div className="about2__icon">
                                                    <i className="flaticon-fair-trade"></i>
                                                </div>
                                                <div className="about2__content">
                                                    <h4><a href={process.env.PUBLIC_URL + '/become-a-partner-associate-for-ksmu'} >Become A Partner</a></h4>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                                            <div className="about2__item about2-border d-flex  mb-30 mr-20">
                                                <div className="about2__icon">
                                                    <i className="flaticon-check"></i>
                                                </div>
                                                <div className="about2__content">
                                                    <h4><a href={process.env.PUBLIC_URL + '/apply-now'} >Apply Now</a></h4>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <a href={process.env.PUBLIC_URL + '/about-doctors-iea'} className="theme-btn">Read More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="steps-area pb-80 wow fadeInUp" data-wow-delay="0.3s" >
                    <div className="container">
                        <div className="steps-br">
                            <img src="images/steps-br.png" alt="" />
                        </div>
                        <div className="row">

                            <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-6">
                                <div className="steps-box text-center mb-30">
                                    <div className="steps-box__icon mb-35">
                                        <span className="icon"><img src='images/s1.jpg' className='img-fluid border-radius' /></span>
                                    </div>
                                    <div className="steps-box__content">
                                        <h4 className="mb-25"><a href="#">General Medicine </a></h4>
                                        <p>Price: 4,75,000 Rubles</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-6">
                                <div className="steps-box text-center mb-30">
                                    <div className="steps-box__icon mb-35">
                                        <span className="icon"><img src='images/s2.jpg' className='img-fluid border-radius' /></span>
                                    </div>
                                    <div className="steps-box__content">
                                        <h4 className="mb-25"><a href="#">Dentistry</a></h4>
                                        <p>Price: 4,95,100 Rubles</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-6">
                                <div className="steps-box text-center mb-30">
                                    <div className="steps-box__icon mb-35">
                                        <span className="icon"><img src='images/s3.jpg' className='img-fluid border-radius' /></span>

                                    </div>
                                    <div className="steps-box__content">
                                        <h4 className="mb-25"><a href="#">Pharmacy</a></h4>
                                        <p>Price: 2,77,000 Rubles</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-6">
                                <div className="steps-box text-center mb-30">
                                    <div className="steps-box__icon mb-35">
                                        <span className="icon"><img src='images/s4.jpg' className='img-fluid border-radius' /></span>

                                    </div>
                                    <div className="steps-box__content">
                                        <h4 className="mb-25"><a href="#">Pediatrics</a></h4>
                                        <p>Price: 3,11,520  Rubles</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-6">
                                <div className="steps-box text-center mb-30">
                                    <div className="steps-box__icon mb-35">
                                        <span className="icon"><img src='images/s5.jpg' className='img-fluid border-radius' /></span>

                                    </div>
                                    <div className="steps-box__content">
                                        <h4 className="mb-25"><a href="#">Preparatory Faculty</a></h4>
                                        <p>Price: 1,30,000 Rubles</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-6">
                                <div className="steps-box text-center mb-30">
                                    <div className="steps-box__icon mb-35">
                                        <span className="icon"><img src='images/s6.jpg' className='img-fluid border-radius' /></span>

                                    </div>
                                    <div className="steps-box__content">
                                        <h4 className="mb-25"><a href="#">Post-Graduation</a></h4>
                                        <p>Price: 2,94,650  Rubles</p>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </section>

                <section className="callto-action-2 d-flex align-items-center theme-bg wow fadeInUp">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-8">
                                <div className="callto-title">
                                    <h3>Be Doctor from A-Grade University</h3>

                                </div>
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4">
                                <div className="callto-btn text-right">
                                    <a href={process.env.PUBLIC_URL + '/ksmukazan-official-admission-partner'} className="theme-btn clto-btn-2">Contact Us</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="features-services pt-80 pb-120  wow fadeInUp" data-wow-delay="0.3s">
                    <div className="container ">
                        <div className="row justify-content-center">
                            <div className="col-xxl-10">
                                <div className="section_title_wrapper text-center mb-50">
                                    <span className="subtitle">
                                        Kazan State Medical University – The Best University for MBBS in Russia
                                    </span>
                                    <h2 className="section-title">
                                        Kazan State Medical University <br /> The Best University for MBBS in Russia
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <div className="row g-0">
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                                <div className="features2">
                                    <div className="features2__contant d-flex align-items-center mb-20">
                                        <div className="features2__icon mr-15">
                                            <i className="flaticon-admission"></i>
                                        </div>
                                        <h4 className="features2__title">
                                            <a href={process.env.PUBLIC_URL + '/kazan-state-medical-university'}> Kazan State Medical University </a>
                                        </h4>
                                    </div>
                                    <div className="features2__wrapper">
                                        <p className="features2__wrapper__subtitle mb-25">As per the trend in the last decade or two, Russian Universities have emerged as the top destination for MBBS aspirants.</p>
                                        <a className="browse-link read-more" href={process.env.PUBLIC_URL + '/kazan-state-medical-university'}>Read More <i className="fal fa-long-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                                <div className="features2">
                                    <div className="features2__contant d-flex align-items-center mb-20">
                                        <div className="features2__icon mr-15">
                                            <i className="flaticon-coach"></i>
                                        </div>
                                        <h4 className="features2__title">
                                            <a href={process.env.PUBLIC_URL + '/about-ksmu'}> About KSMU</a>
                                        </h4>
                                    </div>
                                    <div className="features2__wrapper">
                                        <p className="features2__wrapper__subtitle mb-25">Undoubtedly, Kazan State Medical University is one of the most popular and leading universities for medical education. </p>
                                        <a className="browse-link read-more" href={process.env.PUBLIC_URL + '/about-ksmu'}>Read More <i className="fal fa-long-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                                <div className="features2">
                                    <div className="features2__contant d-flex align-items-center mb-20">
                                        <div className="features2__icon mr-15">
                                            <i className="flaticon-hands"></i>
                                        </div>
                                        <h4 className="features2__title">
                                            <a href={process.env.PUBLIC_URL + '/admission'}>Admission </a>
                                        </h4>
                                    </div>
                                    <div className="features2__wrapper">
                                        <p className="features2__wrapper__subtitle mb-25">Students can apply for admission to KSMU in July. The academic session or admission intake starts for KSMU in September / October.</p>
                                        <a className="browse-link read-more" href={process.env.PUBLIC_URL + '/admission'}>Read More <i className="fal fa-long-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                                <div className="features2">
                                    <div className="features2__contant d-flex align-items-center mb-20">
                                        <div className="features2__icon mr-15">
                                            <i className="flaticon-internship"></i>
                                        </div>
                                        <h4 className="features2__title">
                                            <a href={process.env.PUBLIC_URL + '/hostel-facility'}>Hostel Facility</a>
                                        </h4>
                                    </div>
                                    <div className="features2__wrapper">
                                        <p className="features2__wrapper__subtitle mb-25">Kazan State Medical University's hostel facility is appreciable. KSMU has 6 government hostels, and for the same students can live in private hostels... </p>
                                        <a className="browse-link read-more" href={process.env.PUBLIC_URL + '/hostel-facility'}>Read More <i className="fal fa-long-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                                <div className="features2">
                                    <div className="features2__contant d-flex align-items-center mb-20">
                                        <div className="features2__icon mr-15">
                                            <i className="flaticon-education"></i>
                                        </div>
                                        <h4 className="features2__title">
                                            <a href="#"> What advantages?</a>
                                        </h4>
                                    </div>
                                    <div className="features2__wrapper">
                                        <p className="features2__wrapper__subtitle mb-25">Pursuing MBBS in KSMU is totally a different experience for students enrolling there from India or other countries. The teachers have abundant knowledge and experience.</p>
                                        <a className="browse-link read-more" href={process.env.PUBLIC_URL + '/what-advantages'}>Read More <i className="fal fa-long-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                                <div className="features2">
                                    <div className="features2__contant d-flex align-items-center mb-20">
                                        <div className="features2__icon mr-15">
                                            <i className="flaticon-coach"></i>
                                        </div>
                                        <h4 className="features2__title">
                                            <a href={process.env.PUBLIC_URL + '/doctors-ieas'}>Doctors-IEA’s Role</a>
                                        </h4>
                                    </div>
                                    <div className="features2__wrapper">
                                        <p className="features2__wrapper__subtitle mb-25">The Doctors-IEA is the leading consultant for MBBS in Russia. We made admission to KSMU easier and within your reach.</p>
                                        <a className="browse-link read-more" href={process.env.PUBLIC_URL + '/doctors-ieas'}>Read More <i className="fal fa-long-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                                <div className="features2">
                                    <div className="features2__contant d-flex align-items-center mb-20">
                                        <div className="features2__icon mr-15">
                                            <i className="flaticon-check"></i>
                                        </div>
                                        <h4 className="features2__title">
                                            <a href={process.env.PUBLIC_URL + '/indian-mess'}>Indian food availability in city</a>
                                        </h4>
                                    </div>
                                    <div className="features2__wrapper">
                                        <p className="features2__wrapper__subtitle mb-25">

                                            as per now, their is no Indian mess available in university or nearby.</p>
                                        <a className="browse-link read-more" href={process.env.PUBLIC_URL + '/indian-mess'}>Read More <i className="fal fa-long-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <section className="call-to-action3 d-flex align-items-center">
                    <div className="container">
                        <div className="row theme-bg  g-0 pad-cut pt-40 pb-40 pr-80 pl-80">
                            <div className="col-xxl-8 col-xl-8 col-lg-8">
                                <div className="call3-left">
                                    <h2>Official Representative</h2>
                                    <p>of Kazan State Medical University For Indians &amp; Foreign Students Doctors-IEA</p>
                                </div>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 cbr-left d-flex align-items-center justify-content-end">
                                <div className="calltoaction3__wrapper d-flex align-items-center">
                                    <div className="calltoaction3__wrapper__icon mr-10">
                                        <i className="fal fa-headset"></i>
                                    </div>
                                    <div className="calltoaction3__wrapper__call-number">
                                        <span>Call for help</span>
                                        <h5><a href="tel:+917669533991"> +91-7669533991</a></h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="popular-country-2 pt-200 pb-120 youtube-bg">
                    <div className="container">
                        <div className="famous-countries">
                            <Youtube />
                        </div>
                    </div>
                </section>

                <section className="about-area-2 pt-90 pb-85 wow fadeInUp" data-wow-delay="0.3s">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-6 col-xl-6 col-lg-6 mb-30">
                            <div className="section_title_wrapper text-left mb-50 wow fadeInUp" data-wow-delay="0.3s">
                                        <h2 className="section-title"> News & Upcoming Events</h2>
                                    </div>
                                <div className="about2-left d-flex">
									
                                <div className="section_title_wrapper">
                                     
										 <ul>
                <marquee height="200" scrollamount="2" direction="up" onMouseOver="stop()" onMouseOut="start();">
                    <li><div className="widget-latest-posts-thumb__item-meta"><h4>Will Update Soon</h4></div></li>
                    <li><div className="widget-latest-posts-thumb__item-meta"><h4>Will Update Soon</h4></div></li>
                    <li><div className="widget-latest-posts-thumb__item-meta"><h4>Will Update Soon</h4></div></li>
                    <li><div className="widget-latest-posts-thumb__item-meta"><h4>Will Update Soon</h4></div></li>
                    <li><div className="widget-latest-posts-thumb__item-meta"><h4>Will Update Soon</h4></div></li>
                  

                </marquee>
            </ul>

										
										
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-6 col-xl-6 col-lg-6 mb-30">
                                <div className="section_title_wrapper">
                                    <div className="section_title_wrapper text-center mb-50 wow fadeInUp" data-wow-delay="0.3s">
                                        <h2 className="section-title"> Frequently Asked Questions </h2>
                                    </div>
                                    <div className="row">
                                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 wow fadeInUp" data-wow-delay="0.3s">
                                            <div className="faq-content faq-white">
                                                <Faq />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="testimonail-area grey-bg pt-110 pb-190">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xxl-10">
                                <div className="section_title_wrapper text-center mb-50">
                                    <span className="subtitle">
                                        Testimonials
                                    </span>
                                    <h2 className="section-title">
                                        What our students, <br />graduates says about KSMU
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <Clients />
                        </div>



                    </div>
                </section>


            </main>
        )
    }

}

export default Home;
