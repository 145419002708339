import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import SideMenu from '../layout/SideMenu';
import Youtube from '../sliders/Youtube';

export class Ksmukazan_official_admission_partner extends Component {
    render() {

        return (
            <main>
                <Helmet>
                    <title>Official Representative of Kazan State Medical University | Doctors-IEA</title>
                    <meta name="description" content="Kazan state medical university officials, Contact with official admission partner of kazan state medical university for admissions: 8010503035 (IND), +7-9515190376 (RUS). Top Ranked full MBBS Course Six years, BDS 5 years English Medium, Pharmacy 5 years English Medium." />
                    <link rel="canonical" href="https://studymbbsinkazan.com/ksmukazan-official-admission-partner/" />
                    <meta property="og:locale" content="en_US" />
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content="Official Representative of Kazan State Medical University | Doctors-IEA" />
                    <meta property="og:description" content="Kazan state medical university officials, Contact with official admission partner of kazan state medical university for admissions: 8010503035 (IND), +7-9515190376 (RUS). Top Ranked full MBBS Course Six years, BDS 5 years English Medium, Pharmacy 5 years English Medium." />
                    <meta property="og:url" content="https://studymbbsinkazan.com/ksmukazan-official-admission-partner/" />
                    <meta property="og:site_name" content="Kazan State Med University" />
                    <meta property="article:modified_time" content="2024-01-11T06:26:50+00:00" />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:label1" content="Est. reading time" />
                    <meta name="twitter:data1" content="3 minutes" />
                </Helmet>

                <div className="page-title__area pt-110 a1">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-12">
                                <div className="page__title-wrapper text-center">
                                    <h3 className="pb-100">Contact us </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <nav className="breadccrumb-bg">
                        <ul className="breadcrumb justify-content-center pt-20 pb-20">
                            <li className="bd-items"><a href={process.env.PUBLIC_URL + '/'}>Home</a></li>
                            <li className="bd-items bdritems">|</li>
                            <li className="bd-items"> <a href={process.env.PUBLIC_URL + '/Ksmukazan_official_admission_partner'}> Contact us</a></li>
                        </ul>
                    </nav>
                </div>
                <div class="united-stats-area  ptb-100">
                    <div class="container">
                        <div class="row">

                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-sm-12">

                                <div class="sidebar__deatils">
                                    <div class="united-states">

                                        <h2>Kazan State Medical University, Russia</h2>
                                        <h5>Address: Ulitsa Butlerova, 49, Kazan, Tatarstan, 420012<br />
                                            KSMU is the 3rd best medical university according to the ARES-2014 rating.<br /> (Listed in Top 5 Medical Universities, by Indian Embassy)</h5>
                                        <h5>Doctors-Ideal Educational Advisors is the official representative of Kazan State Medical University, Russia for Indian &amp; International Students, to check official representative list visit university official website.</h5>

                                        <p>Many young people around the world dream of studying medicine, but they do not know where to begin. They do not even know the right sources of information and advice for planning their medical studies. The Kazan State Medical University is one of the best-known medical universities in Russia and maybe the address you are looking for. Here the website of Kazan state medical university presented by Doctors-Ideal Educational Advisors who is the official authorized representative of Indian & foreign students. Doctors-IEA offers free counseling services to International students who which to study Medicine at the Kazan State Medical University but are stranded on admission and accommodation procedures, Doctors-IEA is well experienced in the vast education sector, it has its official head office in New Delhi, India, and other branches situated all over India & other countries. To know more about the university, hostels, fees, admission, city; contact the Admission partner of KazanSMU for Indian & foreign citizens; Doctors-IEA at the given address and contact numbers. </p>
                                        <iframe width="100%" className='pt-50' height="415" src="https://www.youtube.com/embed/CDVD8lBW4-s" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                        <div className='row pt-50'>
                                            <div className='col-sm-12'>
                                                <h3>Address</h3>
                                                <ul className="necessary-link mb-40">
                                                    <li><i className="fal fa-check-square"></i>Office No. 608, 6th Floor Aggarwal Cyber Plaza-1, Netaji Subhash Place, Pitampura, New Delhi. Pincode-110034, India</li>

                                                    <li><i className="fal fa-check-square"></i>Web: www.studymbbsinkazan.com | www.thedoctorsiea.in, www.thedoctorsiea.org</li>

                                                    <li><i className="fal fa-check-square"></i>Office: 011-43215827, +91 74282 12236 (Indian Office),  +7-9515190376 (Russian Office) </li>

                                                    <li><i className="fal fa-check-square"></i>Email for students: contact@thedoctorsiea.in, For business inquiries: contact@thedoctorsiea.org</li>
                                                </ul>
                                            </div>

                                            <div className='col-sm-12'>
                                                <h3>Corporate Office:</h3>
                                                <ul className="necessary-link mb-40">
                                                    <li><i className="fal fa-check-square"></i>709-710, 7th floor, PP Trade Center, Netaji Shubhash Place, Pitampura, Pincode-110034, New Delhi (India)</li>

                                                    <li><i className="fal fa-check-square"></i>Indians & Foreign Students can contact us for direct admissions at contact@thedoctorsiea.in, +7-9515190376.</li>


<li><i className="fal fa-check-square"></i>Agents / Sub-agents, also can contact us for admission details, fees structure at: +7-9515190376, +91-7669533991 thedoctors.iea@mail.ru</li>


<li><i className="fal fa-check-square"></i>Dear students, we don't have any sub-agents who are authorized by us, feel free to contact us at: +7-9515190376, +91-7669533991</li>

                                       </ul>
                                            </div>

                                        </div>

                                        <h3>Get Admissions only from official authorized admission partners of Kazan State Medical University. Doctors-IEA is working with KSMU for the last twelve years officially. BEWARE OF UNAUTHORIZED AGENTS.</h3>

                                        <h4>Main Advantages to Study MBBS at Kazan State Medical University, Russia:</h4>
                                        <ul className="necessary-link mb-40">
                                            <li><i className="fal fa-check-square"></i>KSMU is the third oldest Medical University in Russia after Moscow and St. Petersburg. KSMU is recognized by the Ministry of Health &amp; Ministry of Education and Science of the Russian Federation. Kazan State Medical University is a Government-funded university.</li>
                                            <li><i className="fal fa-check-square"></i>KSMU is recognized by the WHO and is listed in the World Directory of Medical Schools (AVICENNA Directory for Medicine), ECFMG (USA), AMC, GMC, NMC, etc.</li>
                                            <li><i className="fal fa-check-square"></i>KSMU is the top university of Russia with Full English medium studies. </li>
                                            <li><i className="fal fa-check-square"></i>One of the largest medical universities of the Republic of Tatarstan.</li>
                                            <li><i className="fal fa-check-square"></i>University has its 7 hostels with good infrastructure. </li>
                                            <li><i className="fal fa-check-square"></i>Kazan International Airport is available in the city, foreign students can take direct flights from their native countries to Kazan. </li>
                                            <li><i className="fal fa-check-square"></i>Visiting professors from India, UK, Germany, Canada every year to deliver lectures on Important topics.</li>
                                            <li><i className="fal fa-check-square"></i>Students who are studying excellent/good, have the opportunity to get a scholarship in their tuition fees from 5 to 15%, as per every year's academic record.</li>
                                            <li><i className="fal fa-check-square"></i>Scientific Conferences throughout the year, where foreign students can participate in their topics. </li>
                                            <li><i className="fal fa-check-square"></i>The only university in Russia &amp; Eastern Europe to have a residency program accredited by the Royal College of Canada International.</li>
                                        </ul>

















                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="popular-country-2 pt-200 pb-120 youtube-bg">
                    <div className="container">
                        <div className="famous-countries">
                            <Youtube />
                        </div>
                    </div>
                </section>
            </main>

        )
    }

}

export default Ksmukazan_official_admission_partner;