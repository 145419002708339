import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import SideMenu from '../layout/SideMenu';
import Youtube from '../sliders/Youtube';

export class Admission_procedure extends Component {
  render() {

    return (
      <main>
        <Helmet>
          <title>Admission Procedure For 2024 Intake of Kazan State Medical University for MBBS</title>
          <meta name="description" content="Kazan State Medical University admission procedure is quite simple, after submitting the online application form to our official representative Doctors-Ideal educational advisors. Your all work will be done by them from A to Z, from admission, visa, ticket arrival, registration, visa extension, etc. For more info call 7428212236." />
          <link rel="canonical" href="https://studymbbsinkazan.com/admission-procedure/" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="Admission Procedure For 2024 Intake of Kazan State Medical University for MBBS" />
          <meta property="og:description" content="Kazan State Medical University admission procedure is quite simple, after submitting the online application form to our official representative Doctors-Ideal educational advisors. Your all work will be done by them from A to Z, from admission, visa, ticket arrival, registration, visa extension, etc. For more info call 7428212236." />
          <meta property="og:url" content="https://studymbbsinkazan.com/admission-procedure/" />
          <meta property="og:site_name" content="Kazan State Med University" />
          <meta property="article:modified_time" content="2024-01-11T06:15:08+00:00" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:label1" content="Est. reading time" />
          <meta name="twitter:data1" content="6 minutes" />
        </Helmet>
        <div className="page-title__area pt-110 a1">
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="page__title-wrapper text-center">
                  <h3 className="pb-100">Admission Procedure </h3>
                </div>
              </div>
            </div>
          </div>
          <nav className="breadccrumb-bg">
            <ul className="breadcrumb justify-content-center pt-20 pb-20">
              <li className="bd-items"><a href={process.env.PUBLIC_URL + '/'}>Home</a></li>
              <li className="bd-items bdritems">|</li>
              <li className="bd-items"> <a href={process.env.PUBLIC_URL + '/Admission_procedure'}> Admission Procedure</a></li>
            </ul>
          </nav>
        </div>


        <div class="united-stats-area  ptb-100">
          <div class="container">
            <div class="row">

              <div class="col-xxl-12 col-xl-12 col-lg-12 col-sm-12">
                <div class="sidebar__deatils">
                  <div class="united-states">


                    <h1>Admission is open for 2024-25 Academic Year</h1>
                    <h2>(Apply for Direct Admission through Doctors-IEA Auth. Off. Rep)</h2>
                    <p>Kazan State Medical University admission starts from May month to September 30 every year. Online Application to Kazan State Medical University is strictly based on merit. See below for eligibility criteria and Kazan state medical university admission procedure:</p>

                    <h3>Eligibility:</h3>
                    <ul class="necessary-link mb-40">
                      <li><i class="fal fa-check-square"></i>Successful completion of higher secondary school.</li>
                      <li><i class="fal fa-check-square"></i>The minimum requirement is an average of 65% marks in higher secondary school (each in chemistry, biology, and English language). </li>
                      <li><i class="fal fa-check-square"></i>Guarantee for the fulfillment of payment conditions.</li>
                    </ul>
                    <h3>Admission Procedure:</h3>
                    <ul class="necessary-link mb-40">
                      <li><i class="fal fa-check-square"></i>Application form. Fill the application form accurately.</li>
                      <li><i class="fal fa-check-square"></i>Documents Submission i.e Passport, Higher Secondary School Certificate.</li>
                      <li><i class="fal fa-check-square"></i>Scanned copy of the certificate of completion of higher secondary education  (should be translated into the Russian language)</li>
                      <li><i class="fal fa-check-square"></i>Scanned copy of your Passport (should be translated into the Russian language)</li>
                      <li><i class="fal fa-check-square"></i>Results of NEET (only for candidates from India)</li>
                      <li><i class="fal fa-check-square"></i>Information Letter (Provisional Admission Letter). After fulfilling all the conditions and upon acceptance you will be provided with an Information letter (provisional admission letter) in electronic format upon request.</li>
                      <li><i class="fal fa-check-square"></i>Confirmed Admission. As soon as your visa invitation will be ready, we will mail you your visa invitation or our official representative Doctors-IEA Team will provide you all the details about it. </li>
                      <li><i class="fal fa-check-square"></i>After you receive the visa invitation, our official representative Doctors-IEA Team will provide you visa from the Russian Embassy in Delhi (For Indian Students only). And for other countries, students will visit the Embassy (Consulate) of the Russian Federation to stamp their visas. More information on visa and registration.</li>
                    </ul>
                    <h5>All documents must be sent to <strong><a href="mailto:thedoctors.iea@gmail.com">thedoctors.iea@gmail.com</a> or <a href="mailto:contact@thedoctorsiea.in">contact@thedoctorsiea.in</a></strong></h5>
                    <h5><strong>Official Contact Details; For Indian Students:<br /> 1800-419-5827 (Toll-free) | For Other Countries: +7-951-517-45-07</strong></h5>

                    <h5>Entrance Exam:</h5>
                    <ul class="necessary-link mb-40">
                      <li><i class="fal fa-check-square"></i>Chemistry</li>
                      <li><i class="fal fa-check-square"></i>English</li>
                    </ul>

                    <h5>For more details contact our representative: Doctors-IEA Pvt. Ltd</h5>

                    <p>Official Contact Details; For Indian Students: <a href="tel:1800-419-5827">1800-419-5827</a> (Toll-free) | For Other Countries: <a href="tel:+7-951-517-45-07">+7-951-517-45-07</a></p>

                    <p>Note please: After arrival to university, a student must submit a passport copy with Russian translation+Notary, Secondary school certificate copy with Russian translation+Notary, Medical Fitness Certificate, HIV test report, Chest X-ray report from the native country, These all documents hard copies must be with the student, then Dean office will verify all documents and confirmed the admission, the student will sign a contract with the university for his/her studies and starts their registration and visa extension work. After that student's classes will start as per time table.</p>

                    <p>How a student can apply for direct admission to KSMU with Doctors-IEA; Russia has been for the longest time the ideal place to study medicine. Kazan state medical university is one of the oldest and reputed universities in the country. Kazan state medical university is recognized by the Ministry of Health & Education Russian Federation, NMC, WHO, ECFMG, GMC, and several other top medical councils like Asia, Africa, Arab, and European medical councils. The student can apply on our website by completing the online application form. After submission of the form, the Doctors-IEA representative will contact you regarding Kazan state medical university admission procedure, fees, and other formalities regarding admission. Russia is one of the fastest-growing countries in the world, has a good standard of Medical studies with a very low cost of living. The Russian government has put great effort into the development of the university. This is to maintain high standards of higher education.</p>

                    <h4>FAQs for Studying MBBS in KSMU, Russia</h4>
                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 wow fadeInUp" data-wow-delay="0.3s">
                      <div className="faq-content faq-white">


                        <div className="accordion" id="accordionExample-1">
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne-1">
                              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne-1" aria-expanded="true" aria-controls="collapseOne-1">
                                Why should I study MBBS in KSMU, Russia?
                              </button>
                            </h2>
                            <div id="collapseOne-1" className="accordion-collapse collapse show" aria-labelledby="headingOne-1" data-bs-parent="#accordionExample-1">
                              <div className="accordion-body">
                                <p>The standard of education in KSMU is considered to be one of the most advanced and sophisticated in Russia. KSMU Medical Degree has received global recognition and has topped the World Health Organization (WHO), Medical Council of India (MCI).  KSMU degree is recognized globally.</p>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo-1">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo-1" aria-expanded="false" aria-controls="collapseTwo-1">
                                Is KSMU recognized by NMC India?
                              </button>
                            </h2>
                            <div id="collapseTwo-1" className="accordion-collapse collapse" aria-labelledby="headingTwo-1" data-bs-parent="#accordionExample-1">
                              <div className="accordion-body">
                                <p>Yes.</p>

                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree-1">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree-1" aria-expanded="false" aria-controls="collapseThree-1">
                                How many years is MBBS in KSMU, Russia?
                              </button>
                            </h2>
                            <div id="collapseThree-1" className="accordion-collapse collapse" aria-labelledby="headingThree-1" data-bs-parent="#accordionExample-1">
                              <div className="accordion-body">
                                <p>The duration of an MBBS in KSMU, Russia is 6 years.</p>


                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree1-1">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree1-1" aria-expanded="false" aria-controls="collapseThree1-1">
                                Is NEET required to Get Admission in KSMU?
                              </button>
                            </h2>
                            <div id="collapseThree1-1" className="accordion-collapse collapse" aria-labelledby="headingThree1-1" data-bs-parent="#accordionExample-1">
                              <div className="accordion-body">
                                <p> Yes, for Indian students, NEET eligibility is mandatory. Without that no admission.</p>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree2-1">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree2-1" aria-expanded="false" aria-controls="collapseThree2-1">
                                What are classrooms environment in KSMU alike?
                              </button>
                            </h2>
                            <div id="collapseThree2-1" className="accordion-collapse collapse" aria-labelledby="headingThree2-1" data-bs-parent="#accordionExample-1">
                              <div className="accordion-body">
                                <p>  In most Russian universities, there is generally a teacher-student ratio of 1:14, which gives you a more attentive and personal learning environment.</p>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree3-1">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree3-1" aria-expanded="false" aria-controls="collapseThree3-1">
                                Is it full English Medium, MBBS course in KSMU?
                              </button>
                            </h2>
                            <div id="collapseThree3-1" className="accordion-collapse collapse" aria-labelledby="headingThree3-1" data-bs-parent="#accordionExample-1">
                              <div className="accordion-body">
                                <p>Yes, Full MBBS Course in English Medium, that's why KSMU having excellent results in the exam.</p>
                              </div>
                            </div>
                          </div>


                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree4-1">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree4-1" aria-expanded="false" aria-controls="collapseThree4-1">
                                How to get direct MBBS Admission in KSMU, Russia?
                              </button>
                            </h2>
                            <div id="collapseThree4-1" className="accordion-collapse collapse" aria-labelledby="headingThree4-1" data-bs-parent="#accordionExample-1">
                              <div className="accordion-body">
                                <p>For that, you have to contact Doctors-IEA Pvt Ltd. the official authorized representative of university for Indian & Foreign students. or contact Dr. Abhineet Kumar (MBBS, MS-Russia), who is the head of the Doctors-IEA Team.</p>
                              </div>
                            </div>
                          </div>


                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree5-1">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree5-1" aria-expanded="false" aria-controls="collapseThree5-1">
                                Highlights of Kazan
                              </button>
                            </h2>
                            <div id="collapseThree5-1" className="accordion-collapse collapse" aria-labelledby="headingThree5-1" data-bs-parent="#accordionExample-1">
                              <div className="accordion-body">
                                <p>Kazan is one of the most diverse, historical and beautiful cities of Russia. Most Famous landmark of Kazan city is Kremlin. Kazan is one of the oldest cities not only in Russia but in Eastern Europe also. Kazan has two pedestrian streets: Bauman and Peterburzhskaya streets In terms of sport, Kazan is one of the most developed cities in Russia. Metro of Kazan is one of the shortest metros in the world. The largest river of Eastern Europe flows through the Tatarstan-Volga river. Two official languages in Kazan city: Tatarian and Russian. International Airpot is one hour far from the main city. Kazan Railway station is thirty minutes far from the airport and nearby city.  </p>
                              </div>
                            </div>
                          </div>


                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree6-1">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree6-1" aria-expanded="false" aria-controls="collapseThree6-1">
                                Why Doctors-IEA only?
                              </button>
                            </h2>
                            <div id="collapseThree6-1" className="accordion-collapse collapse" aria-labelledby="headingThree6-1" data-bs-parent="#accordionExample-1">
                              <div className="accordion-body">
                                <h4>Some reasons which make Doctors-IEA Most trusted educational consultants for Russia.</h4>
                                <ul class="necessary-link mb-40">
                                  <li><i class="fal fa-check-square"></i>Doctors-IEA Pvt. Ltd is the official authorized representative of Kazan state medical university in India. Doctors-IEA has the full experience to provide professional consulting services for students who plan to study Medical in KazanSMU, Russia.</li>
                                  <li><i class="fal fa-check-square"></i>Doctors-IEA has close contact with KazanSMU for the course information, application requirements, and procedures. Our team is fully ready to handle student applications effectively and efficiently.</li>
                                  <li><i class="fal fa-check-square"></i>Doctors-IEA sincerely cares for our student's future and is committed to providing reliable information and keeping to our promise.</li>
                                  <li><i class="fal fa-check-square"></i>Doctors-IEA Team is well recognized for its reliability. For its professional service and teams of experienced staff in the representative offices, Doctors-IEA Team is highly regarded within the industry by universities, parents, and students alike.</li>
                                  <li><i class="fal fa-check-square"></i>Doctors-IEA never hides any facts and truths.</li>
                                  <li><i class="fal fa-check-square"></i>Doctors-IEA never charges any hidden expenses.</li>
                                  <li><i class="fal fa-check-square"></i>Doctors-IEA has an excellent student-parent support system.</li>
                                  <li><i class="fal fa-check-square"></i>Doctors-IEA Assure financial &amp; educational assistance without any hurdle.</li>
                                  <li><i class="fal fa-check-square"></i>Doctors-IEA Team will assist in the whole process of admission including your visa and travel.</li>
                                  <li><i class="fal fa-check-square"></i>Doctors-IEA having a team of representatives in KSMU who supports students throughout the academic year and up to his/her degree completion.</li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree7-1">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree7-1" aria-expanded="false" aria-controls="collapseThree7-1">
                                How To Get An Admission For MBBS in KSMU, Russia?
                              </button>
                            </h2>
                            <div id="collapseThree7-1" className="accordion-collapse collapse" aria-labelledby="headingThree7-1" data-bs-parent="#accordionExample-1">
                              <div className="accordion-body">
                                <h4>How to get admission:</h4>
                                <ul>
                                  <li><i className="fal fa-check-square"></i>The first step, fill the application correctly.</li>
                                  <li><i className="fal fa-check-square"></i>Submit original documents to Delhi Office.</li>
                                  <li><i className="fal fa-check-square"></i>You will receive an admission letter.</li>
                                  <li><i className="fal fa-check-square"></i>Pay the application/processing charges.</li>
                                  <li><i className="fal fa-check-square"></i>You will receive an invitation letter.</li>
                                  <li><i className="fal fa-check-square"></i>The Doctors-IEA team will apply for a visa for you.</li>
                                  <li><i className="fal fa-check-square"></i> After receiving a visa, the Doctors-IEA team will provide the ticket.</li>
                                  <li><i className="fal fa-check-square"></i>That's all &amp; take off for Kazan, Russia</li>
                                </ul>

                              </div>
                            </div>
                          </div>

                        </div>


                      </div>
                    </div>

                    <div className='row pt-50'>
                      <div className='col-sm-6'>
                        <h3>KSMU Indian Students Reviews</h3>
                        <iframe width="100%" height="315" src="https://www.youtube.com/embed/KcGQxWGIvRc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                      </div>

                      <div className='col-sm-6'>
                        <h3>Direct MBBS Admission in KSMU</h3>
                        <iframe width="100%" height="315" src="https://www.youtube.com/embed/ZY4nYEvZYCM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                      </div>

                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="popular-country-2 pt-200 pb-120 youtube-bg">
          <div className="container">
            <div className="famous-countries">
              <Youtube />
            </div>
          </div>
        </section>
      </main>

    )
  }

}

export default Admission_procedure;