import React, { Component } from 'react';


export class Topheader extends Component {
    render() {

        return (

            <div className="row align-items-center">

                <div className="col-xxl-6 col-lg-6">
                    <div className="header-top-left">
                        <ul>
                            <li className='text-center-1'><span>Opening Time :</span> 9 AM to 6 PM</li>
                           
                        </ul>
                    </div>
                </div>

                <div className="col-xxl-6 col-lg-6">
                    <div className="topheader-info">
                        <div className="top-button f-right desk-non">
                            <a href="#">Gallery</a>
                        </div>
                        <div className="top-button f-right desk-non">
                            <a href="#">Videos </a>
                        </div>
                        <div className="top-button f-right desk-non">
                            <a href="#">Downloads</a>
                        </div>
                     
                        <div className="top-button f-right ">
                            <a href={process.env.PUBLIC_URL + '/apply-now'}>Apply Now</a>
                        </div>


                    </div>
                </div>


            </div>

        )
    }
}
export default Topheader;