import React, { Component } from 'react';
import { createBrowserHistory } from 'history';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';
import "swiper/css/bundle";

import { Footer } from './components/layout/Footer';
import { Header } from './components/layout/Header';

import Home from './components/Home';
import About_doctors_iea from './components/pages/About_doctors_iea';
import Kazan_state_medical_university_russia from './components/pages/Kazan_state_medical_university_russia';
import Ksmukazan_official_admission_partner from './components/pages/Ksmukazan_official_admission_partner';
import Admission_procedure from './components/pages/Admission_procedure';
import Become_a_partner_associate_for_ksmu from './components/pages/Become_a_partner_associate_for_ksmu';
import Mbbs_in_russia_from_kazan_city from './components/pages/Mbbs_in_russia_from_kazan_city';
import Apply_now from './components/pages/Apply_now';
import Kazan_State_Medical_University from './components/pages/Kazan_State_Medical_University';
import About_ksmu from './components/pages/About_ksmu';
import Admission from './components/pages/Admission';
import What_advantages from './components/pages/What_advantages';
import Doctors_ieas from './components/pages/Doctors_ieas';
import Hostel_facility from './components/pages/Hostel_facility';
import Indian_mess from './components/pages/Indian_mess';
import Faculties_fees from './components/pages/Faculties_fees';
import Thank_you from "./components/pages/Thank_you";
import Our_offices from './components/pages/Our_offices';

const appHistory = {
  basename: process.env.PUBLIC_URL
};
const history = createBrowserHistory(appHistory);
function App() {

  return (
    <div className="page">
      <Header />

      <Router basename={process.env.PUBLIC_URL} history={history}>
        <Route exact path='/' component={Home} />
        <Route exact path="/about-doctors-iea" component={About_doctors_iea} />
        <Route exact path="/kazan-state-medical-university-russia" component={Kazan_state_medical_university_russia} />
        <Route exact path="/admission-procedure" component={Admission_procedure} />
        <Route exact path="/become-a-partner-associate-for-ksmu" component={Become_a_partner_associate_for_ksmu} />
        <Route exact path="/mbbs-in-russia-from-kazan-city" component={Mbbs_in_russia_from_kazan_city} />
        <Route exact path="/apply-now" component={Apply_now} />
        <Route exact path="/ksmukazan-official-admission-partner" component={Ksmukazan_official_admission_partner} />
        <Route exact path="/kazan-state-medical-university" component={Kazan_State_Medical_University} />
        <Route exact path="/about-ksmu" component={About_ksmu} />
        <Route exact path="/admission" component={Admission} />
        <Route exact path="/what-advantages" component={What_advantages} />
        <Route exact path="/doctors-ieas" component={Doctors_ieas} />
        <Route exact path="/hostel-facility" component={Hostel_facility} />
        <Route exact path="/indian-mess" component={Indian_mess} />
        <Route exact path="/faculties-fees" component={Faculties_fees} />
        <Route exact path="/thank-you" component={Thank_you} />
        <Route exact path="/our-offices" component={Our_offices} />



        <Footer />
      </Router>


    </div>
  );
}

export default App;
