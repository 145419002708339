import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import SideMenu from '../layout/SideMenu';
import Youtube from '../sliders/Youtube';


export class Admission extends Component {
    render() {

        return (       
           <main>
               

    <div className="page-title__area pt-110 a1">
    <div className="container">
        <div className="row">
           <div className="col-xxl-12">
              <div className="page__title-wrapper text-center">
                 <h3 className="pb-100">Admission </h3>
              </div>
           </div>
        </div>
     </div>
     <nav className="breadccrumb-bg">
        <ul className="breadcrumb justify-content-center pt-20 pb-20">
           <li className="bd-items"><a href={process.env.PUBLIC_URL + '/'}>Home</a></li>
           <li className="bd-items bdritems">|</li>
           <li className="bd-items"> <a href={process.env.PUBLIC_URL + '/admission'}> Admission</a></li>
        </ul>
     </nav>
</div>


<div class="united-stats-area  ptb-100">
    <div class="container">
        <div class="row">
       
        <div class="col-xxl-12 col-xl-12 col-lg-12 col-sm-12">

            <div class="sidebar__deatils">
                    <div class="united-states">

                    <h1>Admission:</h1>
 <ul className="necessary-link mb-40">
  <li><i className="fal fa-check-square"></i>Students can apply for admission to KSMU in July.</li>
  <li><i className="fal fa-check-square"></i>The academic session or admission intake starts for KSMU in September / October.</li>
  <li><i className="fal fa-check-square"></i>The MBBS course begins in October.</li>
  <li><i className="fal fa-check-square"></i>There are limited seats for MBBS admission in KSMU.</li>
  <li><i className="fal fa-check-square"></i>Admission is offered on the first-come, first-serve policy.</li>
  <li><i className="fal fa-check-square"></i>The considerable or eligible students for admission in KSMU will be those who apply before the deadline date.</li>
  <li><i className="fal fa-check-square"></i>Only those applications will be entertained whose form submission and documentation fulfill the eligibility criteria.</li>
  <li><i className="fal fa-check-square"></i>The team of <strong>Doctors-IEA</strong> will help interested candidates in online form applications and document submissions. We have zero rejection records so far.</li>
</ul>
<h2>Admission Procedure, Eligibility, and Process</h2>
<h3>Eligibility Criteria:</h3>
 <ul className="necessary-link mb-40">
  <li><i className="fal fa-check-square"></i>The minimum qualification age is 17 on or before 31st Dec of the year in which seeking admission.</li>
  <li><i className="fal fa-check-square"></i>The candidate must have passed his 10th and higher secondary education (12th) from a recognized board or organization.</li>
  <li><i className="fal fa-check-square"></i>The candidate must have cleared the NEET examination for <strong>Kazan State Medical University admission</strong> in KSMU.</li>
  <li><i className="fal fa-check-square"></i>There should be a minimum of<strong> 65%  in Chemistry, Biology, and English</strong> for each subject.</li>
  <li><i className="fal fa-check-square"></i>The candidate must be medically fit and have no contagious disease.</li>
</ul>
<h3>Document Required:</h3>
 <ul className="necessary-link mb-40">
  <li><i className="fal fa-check-square"></i>Scanned copy of 10th &amp; 12th mark sheets, Notarized birth certificate, Migration certificate.</li>
  <li><i className="fal fa-check-square"></i>Valid passport with a minimum validity of 2 years.</li>
  <li><i className="fal fa-check-square"></i><strong>NEET Scorecard (Qualified Marks).</strong></li>
  <li><i className="fal fa-check-square"></i>20 passport size photos with white background.</li>
  <li><i className="fal fa-check-square"></i>Scanned copy of your Adhaar Card and PAN Card.</li>
  <li><i className="fal fa-check-square"></i>Medical certificate (HIV test report, Chest X-ray, Medical Fitness report)</li>
</ul>
<h3>Admission Process at a Glance</h3>
 <ul className="necessary-link mb-40">
  <li><i className="fal fa-check-square"></i>Visit the online portal of Doctors-IEA to apply for admission.</li>
  <li><i className="fal fa-check-square"></i>Fill up the requisite information in the online application form carefully.</li>
  <li><i className="fal fa-check-square"></i>You will hear from the Doctors-IEA team and university within 7-10 days after your online form submission.</li>
  <li><i className="fal fa-check-square"></i>The confirmation letter will be provided by the university if you are selected.</li>
  <li><i className="fal fa-check-square"></i>After this, you must submit all the relevant documents.</li>
  <li><i className="fal fa-check-square"></i>Then you will receive an invitation letter.</li>
  <li><i className="fal fa-check-square"></i>After your Visa formalities are done, you are now ready to fly to KSMU, Russia.</li>
  <li><i className="fal fa-check-square"></i>Now you have to Pay the<strong> Kazan State Medical University MBBS fees</strong> and get the receipt.</li>
  <li><i className="fal fa-check-square"></i>The team of Doctors-IEA will arrange your flight ticket and set of documents to fly with the group of students and representatives. For any further information, you can refer to <strong>Kazan State Medical University’s representative website https://studymbbsinkazan.com/.</strong> You may also contact us for any help at 1800-419-5827.</li>
</ul>

          
      
                    </div>
                    </div>
                </div>
            </div>
            </div>
            </div>
            <section className="popular-country-2 pt-200 pb-120 youtube-bg">
    <div className="container">
	<div className="famous-countries">
		<Youtube />
		</div>
	</div>
</section>

           </main>
          



 )
}

}

export default Admission;