import React, { Component } from "react";
import Socialmedia from "./Socialmedia";

export class Footer extends Component {
    render() {
        return (
            <footer>
                <div className="footer__area footer-bg2 pt-95 pb-70 footer-bg" >
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
                                <div className="footer__widget mb-30">
                                    <div className="footer__widget-title">
                                        <div className="logo mb-30">
                                            <a href={process.env.PUBLIC_URL + '/'}><img src="images/f-logo.png" alt="logo" /></a>
                                        </div>
                                    </div>
                                    <div className="footer__widget-content">
                                        <div className="footer__logo-area">
                                            <p>Doctors-IEA Pvt. Ltd <br />(Doctors-Ideal Educational Advisors) is the authorized Official representative of Indians and International students in KazanSMU, Russia</p>
                                            <div className="social">
                                                <ul>
                                                    <li><a href="https://www.facebook.com/DoctorsIEATeamOfficialpage/" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                                                    <li><a href="https://twitter.com/doctors_ieateam" target="_blank"><i className="fab fa-twitter"></i></a></li>
                                                    <li><a href="https://www.instagram.com/doctors.iea_officials/" target="_blank"><i className="fab fa-instagram"></i></a></li>
                                                    <li><a href="https://in.linkedin.com/in/medicalstudyinrussia" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
                                <div className="footer__widget margin-left-100 mb-30">
                                    <div className="footer__widget-title mb-25">
                                        <h2>Quick Links</h2>
                                    </div>
                                    <div className="footer__widget-content">
                                        <div className="footer__services">
                                            <ul>
                                                <li><a href={process.env.PUBLIC_URL + '/'}>Home</a></li>
                                                <li><a href={process.env.PUBLIC_URL + '/kazan-state-medical-university-russia'}>University</a></li>
                                                <li><a href={process.env.PUBLIC_URL + '/apply-now'}>Apply Now</a></li>
                                                <li><a href={process.env.PUBLIC_URL + '/ksmukazan-official-admission-partner'}>Contact Us</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.7s">
                                <div className="footer__widget margin-left-30 mb-30">
                                    <div className="footer__widget-title mb-25">
                                        <h2>Faculties &amp; Fees</h2>
                                    </div>
                                    <div className="footer__widget-content">
                                        <div className="footer__services">
                                            <ul>
                                                <li><a href={process.env.PUBLIC_URL + '/admission-procedure'}>Adm Process</a></li>
                                                <li><a href={process.env.PUBLIC_URL + '/about-doctors-iea'}>Doctors-IEA</a></li>
                                                <li><a href={process.env.PUBLIC_URL + '/become-a-partner-associate-for-ksmu'}>Become a Partner</a></li>
                                                <li><a href={process.env.PUBLIC_URL + '/mbbs-in-russia-from-kazan-city'}>Kazan city</a></li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.9s">
                                <div className="footer-widget mb-30">
                                    <div className="footer__widget-title mb-35">
                                        <h2>Newsletter</h2>
                                    </div>
                                    <div className="subscribe-footer mb-35">
                                        <form action="#">
                                            <input type="email" placeholder="Enter your email" />
                                            <button type="submit"><i className="fal fa-long-arrow-right"></i></button>
                                        </form>
                                    </div>
                                    <div className="footer__services ">
                                        <ul>
                                            <li><span>Opening Time</span> </li>
                                            <li><span> Sun - Thu :    8:30 AM - 9 PM</span> </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-coptright theme-bg">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-6">
                                <div className="footer__text">
                                    <p>Copyright ©2024 studymbbsinkazan.com. All Rights Reserved</p>
                                </div>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                                <div className="footer-copyright__wrapper footer-copyright__wrapper2">
                                    <div className="footer-call d-flex align-items-center">
                                        <div className="footer-copyright__wrapper__icon mr-10">
                                            <i className="fal fa-headset"></i>
                                        </div>
                                        <div className="footer-copyright__wrapper__call-number">
                                            <span>Call - Or - SMS</span>
                                            <h5><a href="tel:+917669533991">+91-7669533991</a></h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Socialmedia />


            </footer>


        )
    }
}

export default Footer;	