import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import SideMenu from '../layout/SideMenu';
import Youtube from '../sliders/Youtube';


export class Thank_you extends Component {
    render() {

        return (       
           <main>
               

    <div className="page-title__area pt-110 a1">
    <div className="container">
        <div className="row">
           <div className="col-xxl-12">
              <div className="page__title-wrapper text-center">
                 <h3 className="pb-100">Thank you </h3>
              </div>
           </div>
        </div>
     </div>
     <nav className="breadccrumb-bg">
        <ul className="breadcrumb justify-content-center pt-20 pb-20">
           <li className="bd-items"><a href={process.env.PUBLIC_URL + '/'}>Home</a></li>
           <li className="bd-items bdritems">|</li>
           <li className="bd-items"> <a href={process.env.PUBLIC_URL + '/'}> Thank you</a></li>
        </ul>
     </nav>
</div>


<div class="united-stats-area  ptb-100">
    <div class="container">
        <div class="row">
        
        <div class="col-xxl-12 col-xl-12 col-lg-12 col-sm-12 text-center">
        <div className="sidebar__deatils">
                    <div className="united-states">

                    <div className="site-header" id="header">
		<h1 className="site-header__title" data-lead-id="site-header-title">THANK YOU!</h1>
	</div>

	<div className="main-content">
		<i className="fa fa-check main-content__checkmark" id="checkmark"></i>
		<p className="main-content__body" data-lead-id="main-content-body">
            Thank you for contacting us.<br/>
We will come back to you in the next 24 hours.<br/>
If this is urgent or if you do not hear back from us in the next 24 hours, please feel free to call us.</p>
	</div>


                   
                    </div>
                    </div>
          
                </div>
            </div>
            </div>
            </div>
            <section className="popular-country-2 pt-200 pb-120 youtube-bg">
    <div className="container">
	<div className="famous-countries">
		<Youtube />
		</div>
	</div>
</section>

           </main>
          



 )
}

}

export default Thank_you;