import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import SideMenu from '../layout/SideMenu';
import Youtube from '../sliders/Youtube';


export class Mbbs_in_russia_from_kazan_city extends Component {
    render() {

        return (
            <main>
                <Helmet>
                    <meta property="og:locale" content="en_US" />
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content="Kazan city at a glance | Kazan state medical university, Russia" />
                    <meta property="og:description" content="Kazan State Med University (Best city for study MBBS in Russia) Go ahead and achieve your goals to become doctor." />
                    <meta property="og:url" content="https://studymbbsinkazan.com/mbbs-in-russia-from-kazan-city/" />
                    <meta property="og:site_name" content="Kazan State Med University" />
                    <meta property="article:modified_time" content="2024-03-31T10:29:07+00:00" />
                    <meta property="og:image" content="https://studymbbsinkazan.com/wp-content/uploads/2019/02/Best-city-kazan-for-study-MBBS-in-Russia-Kazan-medical-university-top-university-of-Russia.jpg" />
                    <meta property="og:image:width" content="1800" />
                    <meta property="og:image:height" content="800" />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:label1" content="Est. reading time" />
                    <meta name="twitter:data1" content="3 minutes" />
                </Helmet>

                <div className="page-title__area pt-110 a1">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-12">
                                <div className="page__title-wrapper text-center">
                                    <h3 className="pb-100">Kazan city at a glance </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <nav className="breadccrumb-bg">
                        <ul className="breadcrumb justify-content-center pt-20 pb-20">
                            <li className="bd-items"><a href={process.env.PUBLIC_URL + '/'}>Home</a></li>
                            <li className="bd-items bdritems">|</li>
                            <li className="bd-items"> <a href={process.env.PUBLIC_URL + '/about_doctors_iea'}> Kazan city at a glance</a></li>
                        </ul>
                    </nav>
                </div>


                <div class="united-stats-area  ptb-100">
                    <div class="container">
                        <div class="row">

                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-sm-12">

                                <div class="sidebar__deatils">
                                    <div class="united-states">

                                        <iframe width="100%" height="415" src="https://www.youtube.com/embed/4GpmpDjkzkw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                        <h2>Kazan city: Famous for Foreign students Education in Russia</h2>
                                        <p>Kazan is the capital and largest city of the Republic of Tatarstan, Russia. It is a major industrial, commercial, and cultural center and remains the most important cultural center of the Tatar culture. It is the eighth-most populous city in Russia. Kazan lies at the confluence of the Volga and Kazanka Rivers in European Russia. Kazan is located 793 Km east of Moscow. Kazan is a place where the East meets the West.</p>
                                        <p>The origin of the name Kazan is unclear. The literal translation of the word Qazan is (Boiler or Cauldron). It is believed that the city of Kazan is named after this object and its geographical similarity with (Qazan-pan); namely, the city is situated in the U-shaped low land. The way from the capital of Russia, Moscow to Kazan takes 1 hour 20 minutes by plane and around 10 hours by train. People of different nationalities live in Kazan. Major religions in Kazan are Sunni Islam and Eastern Orthodoxy. The official languages are Tatar and Russian. Kazan was founded in 1005. Despite such a venerable age, the city is growing and dynamically developing in all aspects. Since April 2009, Kazan has been granted the legal right to brand itself as the third capital of Russia.</p>
                                        <p>In 2004, Kazan was named the best city in Russia and became the winner of the Russian public award (Russian National Olympus). With a rich history and culture, it is a host to a number of monuments. The Kazan Kremlin is a UNESCO World Heritage Site.</p>
                                        <p>Kazan is a significant educational and cultural center of Russia.</p>
                                        <p>Kazan offers education for every taste. There are lots of museums, libraries, concert halls, theaters in this city.<br />
                                            In 2009, Kazan was chosen as «Sports Capital of Russia» and it is still referred to as such. Apart from being a home for Ice-Hockey Team (Ak Bars Kazan), it has a football team by the name of «Rubin Kazan». Some notable international events hosted by Kazan include — 2013 Summer Universiade, World Fencing Championships (2014), European Weightlifting Championships (2011), Bandy World Championship for men (2005 &amp; 2011). Kazan is the host city for the 2015 World Aquatics Championships, 2017 FIFA Confederations Cup, and 2018 FIFA World Cup. Kazan is a city of Peace and Calm.</p>

                                        <h3>The old and reputed university is situated in Kazan:<br />
                                            Kazan State Medical University, Estd. 1814 </h3>
                                        <p>Kazan state medical university is one of the higher education to study MBBS in Russia. It was established way back in 1814, in Kazan, Russia. It offers updated and extensive MBBS studies in Russia. It specifically out stands in how it teaches its students. The process includes the approach, beginning with lectures then followed by laboratories, practical work, an in-depth examination of patients, and clinical duties. This is, however, a general outline of the extensive teaching process used in Kazan state medical university. It was rated the third best university in the country of Russia. Doctors-ideal educational advisors have been of great help to an international student who aspires to study MBBS in Russia. They act as a one-stop consultant for a study MBBS abroad in Russia. They are providing updated information to students; courses offered by KSMU, tuition fees, admission procedures, study opportunities, bi-lateral exchange programs, and other programs. Doctors-IEA has helped Indian students, as well as foreign students to join the KSMU successfully. </p>
                                        <h5>A medical study in Kazan, MBBS in Russia-Kazan state medical university, Study MBBS in Russia, MBBS in Russia-Kazan</h5>

                                        <h4>A medical study in Kazan, MBBS in Russia-Kazan state medical university, Study MBBS in Russia, MBBS in Russia-Kazan
                                        </h4>
                                        <div className='row'>
                                            <div className='col-sm-4'>
                                                <img src='images/mbbs1.jpg' className='img-fluid' alt='' />
                                            </div>
                                            <div className='col-sm-4'>
                                                <img src='images/mbbs2.jpg' className='img-fluid' alt='' />
                                            </div>
                                            <div className='col-sm-4'>
                                                <img src='images/mbbs3.jpg' className='img-fluid' alt='' />
                                            </div>
                                            <div className='col-sm-4'>
                                                <img src='images/mbbs4.jpg' className='img-fluid' alt='' />
                                            </div>
                                            <div className='col-sm-4'>
                                                <img src='images/mbbs5.jpg' className='img-fluid' alt='' />
                                            </div>
                                            <div className='col-sm-4'>
                                                <img src='images/mbbs6.jpg' className='img-fluid' alt='' />
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="popular-country-2 pt-200 pb-120 youtube-bg">
                    <div className="container">
                        <div className="famous-countries">
                            <Youtube />
                        </div>
                    </div>
                </section>

            </main>




        )
    }

}

export default Mbbs_in_russia_from_kazan_city;