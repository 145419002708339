import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import SideMenu from '../layout/SideMenu';
import Youtube from '../sliders/Youtube';


export class What_advantages extends Component {
   render() {

      return (
         <main>


            <div className="page-title__area pt-110 a1">
               <div className="container">
                  <div className="row">
                     <div className="col-xxl-12">
                        <div className="page__title-wrapper text-center">
                           <h3 className="pb-100">What advantages do<br /> Students receive in KSMU?</h3>
                        </div>
                     </div>
                  </div>
               </div>
               <nav className="breadccrumb-bg">
                  <ul className="breadcrumb justify-content-center pt-20 pb-20">
                     <li className="bd-items"><a href={process.env.PUBLIC_URL + '/'}>Home</a></li>
                     <li className="bd-items bdritems">|</li>
                     <li className="bd-items"> <a href={process.env.PUBLIC_URL + '/admission'}> What advantages do Students receive in KSMU?</a></li>
                  </ul>
               </nav>
            </div>


            <div class="united-stats-area  ptb-100">
               <div class="container">
                  <div class="row">

                     <div class="col-xxl-12 col-xl-12 col-lg-12 col-sm-12">
                        <div class="sidebar__deatils">
                           <div class="united-states">

                              <h1>What advantages do Students receive in KSMU?</h1>
                              <p>Pursuing MBBS in KSMU is totally a different experience for students enrolling there from India or other countries. The teachers have abundant knowledge and experience in their fields. Teachers and professors pay equal and undivided attention to each and every student. Students are allotted to the most prolific training and research program. There is a consistent approach from the administration and teaching staff to elevate your performance and progression throughout the course. For all these exceptional teaching and training Kazan State Medical University fees is very nominal because it is a government-funded institution.</p>
                              <p>Apart from studying, students are also encouraged to participate in various extracurricular activities to hone up their collateral skills and personality. They can participate in activities, games, and entertainment such as tennis, basketball, boxing, cricket, football, and skiing in winter. They can mix up with international students and experience dynamic thoughts, culture, and traditions. Overall, they explore and experience healthy and soothing campus life in the university.</p>
                              <h3>MBBS Fees Structure and Course Duration in KSMU 2024</h3>
                              <p>Kazan State Medical University fees are below par as compared to the output of quality education. That is the reason why it attracts students from all over the world. Thus, it yields a great opportunity to grab the best medical education at a low price. </p>
                              <p>Fee Structure of Kazan State Medical University</p>
                              <h3>Opportunity for Indian Student at KSMU in Russia</h3>
                              <p>KSMU will be a yield so many opportunities for those who are willing to obtain greater scope in MBBS. Not only the university degree is globally acknowledged but it facilitates highly-rated skills and knowledge also essential for a future doctor.</p>
                              <ul className="necessary-link mb-40">
                                 <li><i className="fal fa-check-square"></i>Students can enroll in MBBS program through direct admission.</li>
                                 <li><i className="fal fa-check-square"></i>Quality of education is at par with European Universities.</li>
                                 <li><i className="fal fa-check-square"></i>High scope of building a well-defined medical career.</li>
                                 <li><i className="fal fa-check-square"></i>The deserving candidate can avail Kazan State Medical University scholarship facility (5-15% in tuition fees).</li>
                                 <li><i className="fal fa-check-square"></i>International events and exchange programs extensively help students to hone their medical knowledge and skills.</li>
                                 <li><i className="fal fa-check-square"></i>The medium of teaching is English but the Russian language is also taught to the students of English medium in the starting 2-3 years.</li>
                                 <li><i className="fal fa-check-square"></i>Kazan State Medical University MBBS fees are quite feasible.</li>
                              </ul>

                              <div className='row'>
                                 <div className='col-sm-6'>
                                    <h4>(Indian Students Reviews from 1st to 6th year General Medicine (MBBS) – KSMU)</h4>
                                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/KcGQxWGIvRc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                 </div>
                                 <div className='col-sm-6'>
                                    <h4>(Living Cost of Kazan city for International Students)</h4>
                                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/-4TYRgWksGY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>  </div>

                              </div>

                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <section className="popular-country-2 pt-200 pb-120 youtube-bg">
               <div className="container">
                  <div className="famous-countries">
                     <Youtube />
                  </div>
               </div>
            </section>

         </main>




      )
   }

}

export default What_advantages;