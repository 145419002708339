import React, { Component } from 'react';
import { Helmet } from "react-helmet";



class Our_offices extends Component {
    render() {


        return (
            <main>
                <Helmet>

                    <title data-react-helmet="true">Our Offices in India | Doctors-IEA</title>
                    <meta data-react-helmet="true" name="description" content="Doctors-IEA a well-known name for his commitment basis services for Russian Medical, Federal &amp; Technical Universities of Russia. A trusted name for Direct MBBS Admissions in Russia. More than 20 Universities Direct Representative for Russia. #MBBSinRussia #StudyinRussia" />
                    <link data-react-helmet="true" rel="canonical" href="https://thedoctorsiea.in/our-offices/" />
                    <meta data-react-helmet="true" property="og:locale" content="en_US" />
                    <meta data-react-helmet="true" property="og:type" content="article" />
                    <meta data-react-helmet="true" property="og:title" content="Our Offices in India | Doctors-IEA" />
                    <meta data-react-helmet="true" property="og:description" content="Doctors-IEA a well-known name for his commitment basis services for Russian Medical, Federal &amp; Technical Universities of Russia. A trusted name for Direct MBBS Admissions in Russia. More than 20 Universities Direct Representative for Russia. #MBBSinRussia #StudyinRussia" />
                    <meta data-react-helmet="true" property="og:url" content="https://thedoctorsiea.in/our-offices/" />
                    <meta data-react-helmet="true" property="article:publisher" content="https://www.facebook.com/doctorsieaindiarussia/" />
                    <meta data-react-helmet="true" property="article:publisher" content="https://www.facebook.com/doctorsieaindiarussia/" />
                    <meta data-react-helmet="true" name="twitter:card" content="summary" />
                    <meta data-react-helmet="true" name="twitter:label1" content="Est. reading time" />
                    <meta data-react-helmet="true" name="twitter:data1" content="4 minutes" />
                    <meta data-react-helmet="true" property="og:locale" content="en_US" />
                    <meta data-react-helmet="true" property="og:type" content="website" />
                    <meta data-react-helmet="true" property="og:image" content="https://thedoctorsiea.in/static/media/study-mbbs-in-russia.b4f43299acd5981e00d7.webp" />
                    <meta data-react-helmet="true" property="og:image:alt" content="thedoctorsiea" />
                    <meta data-react-helmet="true" property="og:image:width" content="1200" />
                    <meta data-react-helmet="true" property="og:image:height" content="627" />
                    <meta data-react-helmet="true" name="twitter:site" content="@thedoctorsiea" />
                    <meta data-react-helmet="true" name="twitter:creator" content="@thedoctorsiea" />
                    <meta data-react-helmet="true" name="robots" content="max-image-preview:large" />
                    <link data-react-helmet="true" rel="sitemap" type="application/xml" href="/sitemap.xml" />
                    <meta http-equiv="Cache-control" content="public" />
                    <meta data-react-helmet="true" name="msvalidate.01" content="e6983268fb5c48559779e2bd494a0bd5" />
                    <meta data-react-helmet="true" name="google-site-verification" content="google28c9eb98d5d51162" />
                    <meta data-react-helmet="true" name="yandex-verification" content="6d9cca1895881a0e" />
                    <link rel='dns-prefetch' href='//www.googletagmanager.com' />
                    <div>
                        <div itemtype="https://schema.org/" itemscope>
                            <meta itemprop="mpn" content="925872" />
                            <meta itemprop="name" content="Our Offices in India | Doctors-IEA" />
                            <link itemprop="image" href="https://thedoctorsiea.in/static/media/study-mbbs-in-russia.b4f43299acd5981e00d7.webp" />
                            <link itemprop="image" href="https://thedoctorsiea.in/static/media/study-mbbs-in-russia.b4f43299acd5981e00d7.webp" />
                            <link itemprop="image" href="https://thedoctorsiea.in/static/media/study-mbbs-in-russia.b4f43299acd5981e00d7.webp" />
                            <meta itemprop="description" content="Doctors-IEA a well-known name for his commitment basis services for Russian Medical, Federal &amp; Technical Universities of Russia. A trusted name for Direct MBBS Admissions in Russia. More than 20 Universities Direct Representative for Russia. #MBBSinRussia #StudyinRussia" />
                            <div itemprop="offers" itemtype="https://schema.org/Offer" itemscope>
                                <link itemprop="url" href="https://thedoctorsiea.in/our-offices/" />
                            </div>
                            <div itemprop="aggregateRating" itemtype="https://schema.org/AggregateRating" itemscope>
                                <meta itemprop="reviewCount" content="3130" />
                                <meta itemprop="ratingValue" content="5.0" />
                            </div>
                            <div itemprop="review" itemtype="https://schema.org/Review" itemscope>
                                <div itemprop="author" itemtype="https://schema.org/Person" itemscope>
                                    <meta itemprop="name" content="thedoctorsiea.in" />
                                </div>
                                <div itemprop="reviewRating" itemtype="https://schema.org/Rating" itemscope>
                                    <meta itemprop="ratingValue" content="5" />
                                    <meta itemprop="bestRating" content="5" />
                                </div>
                            </div>
                            <meta itemprop="sku" content="0446310786" />
                            <div itemprop="brand" itemtype="https://schema.org/Brand" itemscope>
                                <meta itemprop="name" content="thedoctorsiea.in" />
                            </div>
                        </div>
                    </div>

                </Helmet>



                <div className="page-title__area pt-60 a1">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-12">
                                <div className="page__title-wrapper text-center">
                                    <h3 className="pb-30">Our Branch Offices</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <nav className="breadccrumb-bg">
                        <ul className="breadcrumb justify-content-center pt-20 pb-20">
                            <li className="bd-items"><a href={process.env.PUBLIC_URL + '/'}>Home</a></li>
                            <li className="bd-items bdritems">|</li>
                            <li className="bd-items"> <a href={process.env.PUBLIC_URL + '/our-offices'}> Our Branch Offices </a></li>
                        </ul>
                    </nav>
                </div>

                <div className="contact__area">
                    <div className="container">
                        <div className="contact-group-area pb-70 pt-145">
                            <div className="row">

                                <div className="col-xxl-6 col-xl-6 col-lg-6">

                                    <div className="contact__gpinfo grey-soft2-bg mb-50">
                                        <div className="contact__gpinfo-icon text-center">
                                            <i className="flaticon-pin"></i>
                                        </div>
                                        <div className="contact__gpinfo-content">
                                            <table className="table" cellpadding="8" cellspacing="8">
                                                <tr><th>HEAD OFFICE: The Doctors-IEA Team India</th></tr>

                                                <tr>
                                                    <td><span>Address</span><br />608, 6th Floor Aggarwal Cyber Plaza-1< br /> Netaji Subhash Place, Pitampura< br />New Delhi. Pincode-110034, India</td>
                                                </tr>

                                                <tr>
                                                    <td><span>Student-Helpline</span><br /><a href="tel:011-27358827">011-27358827</a><br />  <a href="tel:1800-419-5827">1800-419-5827</a></td>
                                                </tr>

                                                <tr>
                                                    <td><span>Indian office Numbers</span><br /><a href="tel:+91-7428212236">+91-7428212236</a><br />  <a href="tel:+91-7428203118">+91-7428203118</a></td>
                                                </tr>

                                                <tr>
                                                    <td><span>Russian office Number</span><br /><a href="tel:+7-9515190376">+7-9515190376</a></td>
                                                </tr>

                                                <tr>
                                                    <td><span>Email</span><br /><a href="mailto:contact@thedoctorsiea.in">contact@thedoctorsiea.in</a><br />  <a href="mailto:thedoctors.iea@gmail.com">thedoctors.iea@gmail.com</a></td>
                                                </tr>

                                            </table>
                                        </div>
                                    </div>


                                </div>

                                <div className="col-xxl-6 col-xl-6 col-lg-6">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6998.490889078664!2d77.15903!3d28.71221!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x18541e282325b6d7!2sMBBS%20in%20Russia%20%7C%20Study%20MBBS%20Abroad%20Russia%20%7C%20Doctors-IEA%20(Top%20Consultants%20for%20Russia)!5e0!3m2!1sen!2sin!4v1650299417598!5m2!1sen!2sin" width="100%" height="760" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                                </div>

                                <div className='bg-light pd-50'>
                                    <div className='row'>

                                        <div className="col-xxl-6 col-xl-6 col-lg-6">
                                            <div className="contact__gpinfo grey-soft2-bg mb-50">
                                                <div className="contact__gpinfo-icon text-center">
                                                    <i className="flaticon-pin"></i>
                                                </div>
                                                <div className="contact__gpinfo-content">
                                                    <table className="table" cellpadding="8" cellspacing="8">
                                                        <tr><th>Bihar </th></tr>

                                                        <tr>
                                                            <td><span>Address</span><br />Office No. L - 219, 2nd floor, Dumraon Place<br /> P.S.- Kotwali, Fraser Road, Patna-800001</td>
                                                        </tr>

                                                        <tr>
                                                            <td><span> Contact Numbers</span><br /><a href="tel:+91-7428212236">+91-7428212236</a><br /><a href="tel:+91-9601415827">+91-9601415827</a><br /> <a href="tel:1800-419-5827">1800-419-5827</a></td>
                                                        </tr>

                                                        <tr>
                                                            <td><span>Email</span><br /><a href="mailto:contact@thedoctorsiea.in">contact@thedoctorsiea.in</a></td>
                                                        </tr>

                                                    </table>
                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-xxl-6 col-xl-6 col-lg-6">
                                            <div className="contact__gpinfo grey-soft2-bg mb-50">
                                                <div className="contact__gpinfo-icon text-center">
                                                    <i className="flaticon-pin"></i>
                                                </div>
                                                <div className="contact__gpinfo-content">
                                                    <table className="table" cellpadding="8" cellspacing="8">
                                                        <tr><th>Uttar Pradesh</th></tr>

                                                        <tr>
                                                            <td><span>Address</span><br />1st Floor, T.S. Tower Ashoka Marg<br /> Near Shakti Bhawan - Hazratganj<br />
                                                                Lucknow- 226001, U.P</td>
                                                        </tr>

                                                        <tr>
                                                            <td><span> Contact Number</span><br /><a href="tel:+91-7428212236">+91-7428212236</a><br /><a href="tel:+91-9792410586">+91-9792410586</a></td>
                                                        </tr>

                                                        <tr>
                                                            <td><span>Email</span><br /><a href="mailto:contact@thedoctorsiea.in">contact@thedoctorsiea.in</a></td>
                                                        </tr>

                                                    </table>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-xxl-6 col-xl-6 col-lg-6">
                                            <div className="contact__gpinfo grey-soft2-bg mb-50">
                                                <div className="contact__gpinfo-icon text-center">
                                                    <i className="flaticon-pin"></i>
                                                </div>
                                                <div className="contact__gpinfo-content">
                                                    <table className="table" cellpadding="8" cellspacing="8">
                                                        <tr><th>Gujarat </th></tr>

                                                        <tr>
                                                            <td><span>Address</span><br />S-16, Third Floor, Atlanta Shoppers<br /> Near Reliance Mall, Vesu - Surat, Pincode - 395007 </td>
                                                        </tr>

                                                        <tr>
                                                            <td><span> Contact Number</span><br /><a href="tel:+91-9601415827">+91-9601415827</a>, <a href="tel:+91-8010503035">+91-8010503035</a></td>
                                                        </tr>

                                                        <tr>
                                                            <td><span>Email</span><br /><a href="mailto:contact@thedoctorsiea.in">contact@thedoctorsiea.in</a></td>
                                                        </tr>

                                                    </table>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-xxl-6 col-xl-6 col-lg-6">
                                            <div className="contact__gpinfo grey-soft2-bg mb-50">
                                                <div className="contact__gpinfo-icon text-center">
                                                    <i className="flaticon-pin"></i>
                                                </div>
                                                <div className="contact__gpinfo-content">
                                                    <table className="table" cellpadding="8" cellspacing="8">
                                                        <tr><th>Chhattisgarh </th></tr>

                                                        <tr>
                                                            <td><span>Address</span><br />280, Sector-10, Zonal Market, Bhilai-490006</td>
                                                        </tr>

                                                        <tr>
                                                            <td><span> Contact Numbers</span><br /><a href="tel:+91-7428212236">+91-7428212236</a><br /> <a href="tel:+91-7880283788">+91-7880283788</a></td>
                                                        </tr>

                                                        <tr>
                                                            <td><span>Email</span><br /><a href="mailto:contact@thedoctorsiea.in">contact@thedoctorsiea.in</a></td>
                                                        </tr>

                                                    </table>
                                                </div>
                                            </div>
                                        </div>






                                        <div className="col-xxl-6 col-xl-6 col-lg-6">
                                            <div className="contact__gpinfo grey-soft2-bg mb-50">
                                                <div className="contact__gpinfo-icon text-center">
                                                    <i className="flaticon-pin"></i>
                                                </div>
                                                <div className="contact__gpinfo-content">
                                                    <table className="table" cellpadding="8" cellspacing="8">
                                                        <tr><th>Mumbai (M.H) </th></tr>

                                                        <tr>
                                                            <td><span>Address</span><br />Tolaram Smruti CHS, Office-16<br />
                                                                Near Fertilizer Township Monorail Station<br />
                                                                Chembur East, Mumbai-400074</td>
                                                        </tr>

                                                        <tr>
                                                            <td><span> Contact Numbers</span><br /><a href="tel:+91-7045334570 ">+91-7045334570</a><br /> <a href="tel:+918082481434">+91 8082481434</a></td>
                                                        </tr>

                                                        <tr>
                                                            <td><span>Email</span><br /><a href="mailto:contact@thedoctorsiea.in">contact@thedoctorsiea.in</a></td>
                                                        </tr>

                                                    </table>
                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-xxl-6 col-xl-6 col-lg-6">
                                            <div className="contact__gpinfo grey-soft2-bg mb-50">
                                                <div className="contact__gpinfo-icon text-center">
                                                    <i className="flaticon-pin"></i>
                                                </div>
                                                <div className="contact__gpinfo-content">
                                                    <table className="table" cellpadding="8" cellspacing="8">
                                                        <tr><th> Pune (M.H)  </th></tr>

                                                        <tr>
                                                            <td><span>Address</span><br />Trupti Business Centre: 32 A, Darekar Heights<br />
                                                                1st floor, Office no. 8, Karve Road, Pune – 411004</td>
                                                        </tr>

                                                        <tr>
                                                            <td><span> Contact Number</span><br /><a href="tel:+91-7045334570">+91-7045334570</a><br /> <a href="tel:+918082481434">+91 8082481434</a></td>
                                                        </tr>

                                                        <tr>
                                                            <td><span>Email</span><br /><a href="mailto:contact@thedoctorsiea.in">contact@thedoctorsiea.in</a></td>
                                                        </tr>

                                                    </table>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-xxl-6 col-xl-6 col-lg-6">
                                            <div className="contact__gpinfo grey-soft2-bg mb-50">
                                                <div className="contact__gpinfo-icon text-center">
                                                    <i className="flaticon-pin"></i>
                                                </div>
                                                <div className="contact__gpinfo-content">
                                                    <table className="table" cellpadding="8" cellspacing="8">
                                                        <tr><th> Our South India Representative   </th></tr>


                                                        <tr>
                                                            <td><span> Contact Number</span><br /><a href="tel:+91- 9474277787">+91- 9474277787</a><br /> <a href="tel:+7-9001282729">+7-9001282729</a></td>
                                                        </tr>

                                                    </table>
                                                </div>
                                            </div>
                                        </div>


                                        <h5>Doctors-IEA Pvt. Ltd is the authorized Official Representative for Asia, Africa, Arabia, and European countries, a student can apply with us for Direct Admissions for Study in Russia for all universities and courses, which are mentioned on our website.</h5>

                                        <h6>Worldwide presence: India, Russia, Thailand, Bangladesh, Nepal, Nigeria, Ghana, Swaziland, Tanzania, South Africa, Latin America, Malaysia, Botswana, UAE, Zambia, Cameroon, Saudi Arab, Kenya, Lebanon, Namibia, Somalia, Oman, Kuwait, Mauritius, Zimbabwe, Jordan, Morocco.</h6>

                                        <h6>Special message to students or agents; you can contact us for direct admissions, where we don’t have representatives at +7-9515190376, +91-7428212236.</h6>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <br />
                <br />
                <br />


            </main>




        )
    }

}

export default Our_offices;