import React, { Component } from 'react';


export class Faq extends Component {
  render() {
    return (
      <div className="accordion" id="accordionExample-1">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne-1">
            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne-1" aria-expanded="true" aria-controls="collapseOne-1">
              Why KSMU is a better option in Russia?
            </button>
          </h2>
          <div id="collapseOne-1" className="accordion-collapse collapse show" aria-labelledby="headingOne-1" data-bs-parent="#accordionExample-1">
            <div className="accordion-body">
              <p>KSMU is one of the oldest universities in Russia. It has more than 200 years of experience in this domain. It is the 3rd oldest university in Russia. It has 6 hospitals of its own for Clinical Training of students. More than 1000 students from India and 5000 international students are studying here. It started an English medium course in 2003. It offers an affordable hostel facility. Moreover, the teaching quality is unparalleled.</p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingTwo-1">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo-1" aria-expanded="false" aria-controls="collapseTwo-1">
              Is passing the NEET exam essential?
            </button>
          </h2>
          <div id="collapseTwo-1" className="accordion-collapse collapse" aria-labelledby="headingTwo-1" data-bs-parent="#accordionExample-1">
            <div className="accordion-body">
              <p>Yes, the NEET scorecard is one of the mandatory requirements for getting admission to KSMU. Hence you must qualify NEET before getting enrolled in KSMU.</p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingThree-1">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree-1" aria-expanded="false" aria-controls="collapseThree-1">
              Is IELTS/TOEFL mandatory?
            </button>
          </h2>
          <div id="collapseThree-1" className="accordion-collapse collapse" aria-labelledby="headingThree-1" data-bs-parent="#accordionExample-1">
            <div className="accordion-body">
              <p>NO, it is not required.</p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingThree1-1">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree1-1" aria-expanded="false" aria-controls="collapseThree1-1">
              How is Class Room teaching in KSMU?
            </button>
          </h2>
          <div id="collapseThree1-1" className="accordion-collapse collapse" aria-labelledby="headingThree1-1" data-bs-parent="#accordionExample-1">
            <div className="accordion-body">
              <p>A large group is avoided in KSMU. The average teacher-student ratio is 1:15 or 1:20. It means you will have a group of not more than 15-20 students.</p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingThree2-1">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree2-1" aria-expanded="false" aria-controls="collapseThree2-1">
              How is the library facility?
            </button>
          </h2>
          <div id="collapseThree2-1" className="accordion-collapse collapse" aria-labelledby="headingThree2-1" data-bs-parent="#accordionExample-1">
            <div className="accordion-body">
              <p>The library is well-organized and adequately furnished with thousands of books medical books, journals, etc. which are updated regularly. There is a proper arrangement of learning space in the library. You can issue a library card for personal use also.</p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingThree3-1">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree3-1" aria-expanded="false" aria-controls="collapseThree3-1">
              What about clinical training and hospital visits?
            </button>
          </h2>
          <div id="collapseThree3-1" className="accordion-collapse collapse" aria-labelledby="headingThree3-1" data-bs-parent="#accordionExample-1">
            <div className="accordion-body">
              <p>The clinical training standard is among the best. Students are supposed to get more clinical exposure and frequent hospital visits in KSMU. It has its own 6 hospitals. KSMU focuses more on the practical aspects of learning medical skills and knowledge.</p>
            </div>
          </div>
        </div>


      </div>
    )
  }
}
export default Faq;