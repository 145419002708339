import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export class Youtube extends Component {


    render() {
        var settings = {
            dots: false,
            infinite: false,
            speed: 500,
            autoplay: true,
            autoplaySpeed: 3000,
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 0,
            arrows: false,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <div>
                <Slider {...settings}>


                    <div className="countries-item img-top">
                        <div className="countries-item__top">
                            <div className="countries-item__top-img">
                                <iframe width="100%" height="180" src="https://www.youtube.com/embed/TK1-hpHJi6o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <div className="countries-item__top-img-link">
                                    <a href="https://www.youtube.com/embed/TK1-hpHJi6o" target="_blank"><i className="fal fa-plus"></i></a>
                                </div>
                            </div>
                        </div>





                        <div className="countries-item__bottom d-flex align-items-center">
                            <div className="countries-item__bottom-img mr-20">
                                <img src="images/flag2.png" alt="" />
                            </div>
                            <div className="countries-item__bottom-content">
                                <h5 className="countries-item__bottom-content-title">
                                    <a href="https://www.youtube.com/embed/TK1-hpHJi6o" target="_blank"> Kazan State Medical University Officials | Admission Is OPEN For 2024 <br />(NO Processing Fee)</a>
                                </h5>

                            </div>
                        </div>
                    </div>

                    <div className="countries-item img-top">
                        <div className="countries-item__top">
                            <div className="countries-item__top-img">
                                <iframe width="100%" height="180" src="https://www.youtube.com/embed/gw7ysoObeds" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <div className="countries-item__top-img-link">
                                    <a href="https://www.youtube.com/embed/gw7ysoObeds" target="_blank"><i className="fal fa-plus"></i></a>
                                </div>
                            </div>
                        </div>





                        <div className="countries-item__bottom d-flex align-items-center">
                            <div className="countries-item__bottom-img mr-20">
                                <img src="images/flag2.png" alt="" />
                            </div>
                            <div className="countries-item__bottom-content">
                                <h5 className="countries-item__bottom-content-title">
                                    <a href="https://www.youtube.com/embed/gw7ysoObeds" target="_blank"> Departure of Kazan state medical university - Indian students
                                    </a>
                                </h5>

                            </div>
                        </div>
                    </div>


                    <div className="countries-item img-top">
                        <div className="countries-item__top">
                            <div className="countries-item__top-img">
                                <iframe width="100%" height="180" src="https://www.youtube.com/embed/CDVD8lBW4-s" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <div className="countries-item__top-img-link">
                                    <a href="https://www.youtube.com/embed/CDVD8lBW4-s" target="_blank"><i className="fal fa-plus"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="countries-item__bottom d-flex align-items-center">
                            <div className="countries-item__bottom-img mr-20">
                                <img src="images/flag2.png" alt="" />
                            </div>
                            <div className="countries-item__bottom-content">
                                <h5 className="countries-item__bottom-content-title">
                                    <a href="https://www.youtube.com/embed/CDVD8lBW4-s" target="_blank"> Official view of Kazan state medical university, Russia</a>
                                </h5>

                            </div>
                        </div>
                    </div>


                    <div className="countries-item img-top">
                        <div className="countries-item__top">
                            <div className="countries-item__top-img">
                                <iframe width="100%" height="180" src="https://www.youtube.com/embed/d2DwIl8Foys" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <div className="countries-item__top-img-link">
                                    <a href="https://www.youtube.com/embed/d2DwIl8Foys" target="_blank"><i className="fal fa-plus"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="countries-item__bottom d-flex align-items-center">
                            <div className="countries-item__bottom-img mr-20">
                                <img src="images/flag2.png" alt="" />
                            </div>
                            <div className="countries-item__bottom-content">
                                <h5 className="countries-item__bottom-content-title">
                                    <a href="https://www.youtube.com/embed/d2DwIl8Foys" target="_blank"> General Medicine Faculty - Kazan state medical university
                                    </a>
                                </h5>

                            </div>
                        </div>
                    </div>


                    <div className="countries-item img-top">
                        <div className="countries-item__top">
                            <div className="countries-item__top-img">
                                <iframe width="100%" height="180" src="https://www.youtube.com/embed/MvOuvdxtzUI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <div className="countries-item__top-img-link">
                                    <a href="https://www.youtube.com/embed/MvOuvdxtzUI" title="YouTube video player" target="_blank"><i className="fal fa-plus"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="countries-item__bottom d-flex align-items-center">
                            <div className="countries-item__bottom-img mr-20">
                                <img src="images/flag2.png" alt="" />
                            </div>
                            <div className="countries-item__bottom-content">
                                <h5 className="countries-item__bottom-content-title">
                                    <a href="https://www.youtube.com/embed/MvOuvdxtzUI" title="YouTube video player" target="_blank"> Dentistry Faculty - Kazan state medical university
                                    </a>
                                </h5>

                            </div>
                        </div>
                    </div>


                    <div className="countries-item img-top">
                        <div className="countries-item__top">
                            <div className="countries-item__top-img">
                                <iframe width="100%" height="180" src="https://www.youtube.com/embed/elnmisF0-2M" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <div className="countries-item__top-img-link">
                                    <a href="https://www.youtube.com/embed/elnmisF0-2M" title="YouTube video player" target="_blank"><i className="fal fa-plus"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="countries-item__bottom d-flex align-items-center">
                            <div className="countries-item__bottom-img mr-20">
                                <img src="images/flag2.png" alt="" />
                            </div>
                            <div className="countries-item__bottom-content">
                                <h5 className="countries-item__bottom-content-title">
                                    <a href="https://www.youtube.com/embed/elnmisF0-2M" title="YouTube video player" target="_blank"> Pharmacy faculty - Kazan state medical university
                                    </a>
                                </h5>

                            </div>
                        </div>
                    </div>


                    <div className="countries-item img-top">
                        <div className="countries-item__top">
                            <div className="countries-item__top-img">
                                <iframe width="100%" height="180" src="https://www.youtube.com/embed/4GpmpDjkzkw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <div className="countries-item__top-img-link">
                                    <a href="https://www.youtube.com/embed/4GpmpDjkzkw" title="YouTube video player" target="_blank"><i className="fal fa-plus"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="countries-item__bottom d-flex align-items-center">
                            <div className="countries-item__bottom-img mr-20">
                                <img src="images/flag2.png" alt="" />
                            </div>
                            <div className="countries-item__bottom-content">
                                <h5 className="countries-item__bottom-content-title">
                                    <a href="https://www.youtube.com/embed/4GpmpDjkzkw" title="YouTube video player" target="_blank"> Travel Guidance - For Indian Students
                                    </a>
                                </h5>

                            </div>
                        </div>
                    </div>


                    <div className="countries-item img-top">
                        <div className="countries-item__top">
                            <div className="countries-item__top-img">
                                <iframe width="100%" height="180" src="https://www.youtube.com/embed/-qxfYJxHCdQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <div className="countries-item__top-img-link">
                                    <a href="https://www.youtube.com/embed/-qxfYJxHCdQ" title="YouTube video player" target="_blank"><i className="fal fa-plus"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="countries-item__bottom d-flex align-items-center">
                            <div className="countries-item__bottom-img mr-20">
                                <img src="images/flag2.png" alt="" />
                            </div>
                            <div className="countries-item__bottom-content">
                                <h5 className="countries-item__bottom-content-title">
                                    <a href="https://www.youtube.com/embed/-qxfYJxHCdQ" title="YouTube video player" target="_blank"> Get Together of Indian students - Kazan state medical university
                                    </a>
                                </h5>

                            </div>
                        </div>
                    </div>


                    <div className="countries-item img-top">
                        <div className="countries-item__top">
                            <div className="countries-item__top-img">
                                <iframe width="100%" height="180" src="https://www.youtube.com/embed/KcGQxWGIvRc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <div className="countries-item__top-img-link">
                                    <a href="https://www.youtube.com/embed/KcGQxWGIvRc" title="YouTube video player" target="_blank"><i className="fal fa-plus"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="countries-item__bottom d-flex align-items-center">
                            <div className="countries-item__bottom-img mr-20">
                                <img src="images/flag2.png" alt="" />
                            </div>
                            <div className="countries-item__bottom-content">
                                <h5 className="countries-item__bottom-content-title">
                                    <a href="https://www.youtube.com/embed/KcGQxWGIvRc" title="YouTube video player" target="_blank"> Indian Students Reviews - Kazan state medical university
                                    </a>
                                </h5>

                            </div>
                        </div>
                    </div>


                    <div className="countries-item img-top">
                        <div className="countries-item__top">
                            <div className="countries-item__top-img">
                                <iframe width="100%" height="180" src="https://www.youtube.com/embed/-4TYRgWksGY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <div className="countries-item__top-img-link">
                                    <a href="https://www.youtube.com/embed/-4TYRgWksGY" title="YouTube video player" target="_blank"><i className="fal fa-plus"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="countries-item__bottom d-flex align-items-center">
                            <div className="countries-item__bottom-img mr-20">
                                <img src="images/flag2.png" alt="" />
                            </div>
                            <div className="countries-item__bottom-content">
                                <h5 className="countries-item__bottom-content-title">
                                    <a href="https://www.youtube.com/embed/-4TYRgWksGY" title="YouTube video player" target="_blank"> Living expenses in Kazan city - for International students
                                    </a>
                                </h5>

                            </div>
                        </div>
                    </div>





                    <div className="countries-item img-top">
                        <div className="countries-item__top">
                            <div className="countries-item__top-img">
                                <iframe width="100%" height="180" src="https://www.youtube.com/embed/CDVD8lBW4-s" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <div className="countries-item__top-img-link">
                                    <a href="https://www.youtube.com/embed/CDVD8lBW4-s" target="_blank"><i className="fal fa-plus"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="countries-item__bottom d-flex align-items-center">
                            <div className="countries-item__bottom-img mr-20">
                                <img src="images/flag2.png" alt="" />
                            </div>
                            <div className="countries-item__bottom-content">
                                <h5 className="countries-item__bottom-content-title">
                                    <a href="https://www.youtube.com/embed/CDVD8lBW4-s" target="_blank">Kazan State Medical University</a>
                                </h5>

                            </div>
                        </div>
                    </div>

                    <div className="countries-item img-top">
                        <div className="countries-item__top">
                            <div className="countries-item__top-img">
                                <iframe width="100%" height="180" src="https://www.youtube.com/embed/KcGQxWGIvRc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <div className="countries-item__top-img-link">
                                    <a href="https://www.youtube.com/embed/KcGQxWGIvRc" target="_blank"><i className="fal fa-plus"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="countries-item__bottom d-flex align-items-center">
                            <div className="countries-item__bottom-img mr-20">
                                <img src="images/flag2.png" alt="" />
                            </div>
                            <div className="countries-item__bottom-content">
                                <h5 className="countries-item__bottom-content-title">
                                    <a href="https://www.youtube.com/embed/KcGQxWGIvRc" target="_blank">Opportunity for Indian Student</a>
                                </h5>

                            </div>
                        </div>
                    </div>

                    <div className="countries-item img-top">
                        <div className="countries-item__top">
                            <div className="countries-item__top-img">
                                <iframe width="100%" height="180" src="https://www.youtube.com/embed/-4TYRgWksGY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <div className="countries-item__top-img-link">
                                    <a href="https://www.youtube.com/embed/-4TYRgWksGY" target="_blank"><i className="fal fa-plus"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="countries-item__bottom d-flex align-items-center">
                            <div className="countries-item__bottom-img mr-20">
                                <img src="images/flag2.png" alt="" />
                            </div>
                            <div className="countries-item__bottom-content">
                                <h5 className="countries-item__bottom-content-title">
                                    <a href="https://www.youtube.com/embed/-4TYRgWksGY" target="_blank">Living Cost of Kazan city </a>
                                </h5>

                            </div>
                        </div>
                    </div>

                    <div className="countries-item img-top">
                        <div className="countries-item__top">
                            <div className="countries-item__top-img">
                                <iframe width="100%" height="180" src="https://www.youtube.com/embed/4GpmpDjkzkw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <div className="countries-item__top-img-link">
                                    <a href="https://www.youtube.com/embed/4GpmpDjkzkw" target="_blank"><i className="fal fa-plus"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="countries-item__bottom d-flex align-items-center">
                            <div className="countries-item__bottom-img mr-20">
                                <img src="images/flag2.png" alt="" />
                            </div>
                            <div className="countries-item__bottom-content">
                                <h5 className="countries-item__bottom-content-title">
                                    <a href="https://www.youtube.com/embed/4GpmpDjkzkw" target="_blank"> Kazan State Medical University</a>
                                </h5>

                            </div>
                        </div>
                    </div>

                </Slider>
            </div>
        )
    }
}
export default Youtube;