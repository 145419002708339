import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import SideMenu from '../layout/SideMenu';
import Youtube from '../sliders/Youtube';


export class Hostel_facility extends Component {
    render() {

        return (       
           <main>
               

    <div className="page-title__area pt-110 a1">
    <div className="container">
        <div className="row">
           <div className="col-xxl-12">
              <div className="page__title-wrapper text-center">
                 <h3 className="pb-100">Hostel facility </h3>
              </div>
           </div>
        </div>
     </div>
     <nav className="breadccrumb-bg">
        <ul className="breadcrumb justify-content-center pt-20 pb-20">
           <li className="bd-items"><a href={process.env.PUBLIC_URL + '/'}>Home</a></li>
           <li className="bd-items bdritems">|</li>
           <li className="bd-items"> <a href={process.env.PUBLIC_URL + '/admission'}> Hostel facility</a></li>
        </ul>
     </nav>
</div>


<div class="united-stats-area  ptb-100">
    <div class="container">
        <div class="row">
       
        <div class="col-xxl-12 col-xl-12 col-lg-12 col-sm-12">
            <div class="sidebar__deatils">
                    <div class="united-states">

                    <h1>Kazan State Medical University Hostel Facility</h1>
<p>Kazan State Medical University’s hostel facility is appreciable. KSMU has 6 government hostels, and for the same students can live in private hostels and apartments also as per their choice. The hostel provided to the international students may differ in size and facility. The rooms are available on a sharing basis. Candidates are supposed to share a room with 3-4 students.</p>
<ul className="necessary-link mb-40">
 <li><i className="fal fa-check-square"></i>Weather Condition</li>
</ul>
<p>The weather may not be an issue in summer, however, in winter it can be challenging especially when you are not prepared. The temperature is mild and moderate during the onset of summer, but usually, summer is dry and hot here. In winter, you will experience intense cold. It can be as low as -15 degrees in winter and snowfall is very common.</p>
<h2>What Makes KSMU the Right Place for Medical Aspirants?</h2>
<p>There are several factors that witness and prove the credibility of this university. Some of them are listed below:</p>
<ul className="necessary-link mb-40">
 <li><i className="fal fa-check-square"></i>It is established in 1814, after Moscow and St. Petersburg University, which makes it the third oldest in Russia.</li>
 <li><i className="fal fa-check-square"></i>It is a government-funded university.</li>
 <li><i className="fal fa-check-square"></i>The government gives subsidies on education.</li>
 <li><i className="fal fa-check-square"></i>KSMU has been accredited with a rating of BBB+.</li>
 <li><i className="fal fa-check-square"></i>Kazan State Medical University’s fee structure is low but the quality of medical education is high.</li>
 <li><i className="fal fa-check-square"></i>It has adopted a unique teaching system that empowers graduates to acquire all required knowledge and skills in their domains.</li>
 <li><i className="fal fa-check-square"></i>Graduates from KSMU find jobs easily in the medical sector.</li>
 <li><i className="fal fa-check-square"></i>It has been offering a complete English medium course in medicine for foreign students since 2003.</li>
 <li><i className="fal fa-check-square"></i>Students studying in KSMU bring the best results in the NEXT exam.</li>
 <li><i className="fal fa-check-square"></i>Accredited by World Federation Medical Education (WFME).</li>
 <li><i className="fal fa-check-square"></i>7 Specialty and 2 Bachelor programs.</li>
 <li><i className="fal fa-check-square"></i>More than 1500 staff imparting their service to make your career bright.</li>
 <li><i className="fal fa-check-square"></i>More than 1500  foreign students study at KSMU.</li>
 <li><i className="fal fa-check-square"></i>It has more than 44 Clinical Residencies &amp; 39 Ph.D. programs.</li>
 <li><i className="fal fa-check-square"></i>The university has comprehensive academic contacts with doctors and scientists in France, Germany, the USA, Italy, Belgium, and the Czech Republic.</li>
 <li><i className="fal fa-check-square"></i>In most of the scientific conferences at KSMU, eminent professors and doctors from Canada, the USA, France, Germany delivery their valuable lectures and insight to international students.</li>
 <li><i className="fal fa-check-square"></i>The university is reckoned as one of the most acknowledged centers for science, culture, and education.</li>
</ul>
<h3>Accreditation and Approval of Kazan State Medical University</h3>
<p>KSMU is duly recognized and approved by several eminent medical bodies that may include as such below:</p>
<ul className="necessary-link mb-40">
 <li><i className="fal fa-check-square"></i>World Health Organization (WHO)</li>
 <li><i className="fal fa-check-square"></i>National Medical Commission of India (NMC)</li>
 <li><i className="fal fa-check-square"></i>The Education Commission for Foreign Medical Graduates (ECFMG)</li>
 <li><i className="fal fa-check-square"></i>Ministry of Science and Higher Education of the Russian Federation</li>
 <li><i className="fal fa-check-square"></i>Ministry of Healthcare of Russian Federation</li>
 <li><i className="fal fa-check-square"></i>World Directory of Medical School (WDMS)</li>
 <li><i className="fal fa-check-square"></i>General Medical Council (GMC)</li>
</ul>

          
      
                    </div>
                    </div>
                </div>
            </div>
            </div>
            </div>
            <section className="popular-country-2 pt-200 pb-120 youtube-bg">
    <div className="container">
	<div className="famous-countries">
		<Youtube />
		</div>
	</div>
</section>

           </main>
          



 )
}

}

export default Hostel_facility;