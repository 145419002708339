import React, { Component } from 'react'

export class Logo extends Component {
    render() {

        return (   
            <div className="header-logo ">
            <a href={process.env.PUBLIC_URL + '/'}><img src="images/logo.png"  alt="img" /></a>
        </div>
    
            
     )
    }
}
export default Logo;