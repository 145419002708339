import React, { Component } from 'react'

export class News extends Component {
    render() {

        return (
            <div class="row">
 <div className="col-lg-4">
<span className="blink-text">Admission Helpline:</span>  <a href="tel:+91-7669533991" className="blink-text">+91-7669533991</a>
</div>
                <div className="col-lg-6">
                    
                    <div class="columns small-12">
                        <div class="row large-collapse services-links">
                            <div class="marquee" id="mycrawler">
                                <marquee direction="left" speed={1000}><span className="text-white"><span className="blink-text">Breaking News:</span> Dear students and parents, we are proud to announce that <span className="blink-text">Doctors-IEA is the Direct Admission Partner of the Top 15 Russian Medical Universities</span>. <span className="blink-text">Doctors-IEA is the official authorized representative of Kazan State Medical University since 2011 for Indians and Foreign students</span>. Get Direct Admission now <span className="blink-text">Call +91-7669533991</span>.
                                </span></marquee>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 text-right">
                    <div id="google_translate_element">  </div>
                </div>

            </div>

        )
    }
}
export default News;