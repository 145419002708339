import React, { Component } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import SwiperCore, { FreeMode, Pagination, Autoplay } from "swiper";



// import required modules
import { Navigation } from "swiper";

export class Sliders extends Component {

    
    render() {
        SwiperCore.use([Autoplay]);
        return (
            <>
      <Swiper navigation={true} modules={[Navigation]} className="mySwiper"  autoplay={{ delay: 4000 }}>
      <SwiperSlide><div class="single-slider slider-height d-flex align-items-center swiper-slide" data-swiper-autoplay="5000">
                <img class="slide-bg" src="images/welcome-to-study-medicine-in-kazan-state-medical-university-russia.jpg" />
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="aslider z-index">
                              
                                <h2 class="aslider--title mb-25" data-animation="fadeInUp" data-delay=".7s">Welcome to study Medicine at<br/> Kazan State Medical University
</h2>
                                <p class="aslider--subtitle mb-40" data-animation="fadeInUp" data-delay=".9s">200+ years old government medical university & 3rd oldest medical university of Russia.</p>
                                <div class="aslider--btn" data-animation="fadeInUp" data-delay=".9s">
                                    <a href={process.env.PUBLIC_URL + '/apply-now'} class="theme-btn blacks-hover">Book your seat now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div></SwiperSlide>
            
            
            <SwiperSlide><div class="single-slider slider-height d-flex align-items-center swiper-slide" data-swiper-autoplay="5000">
            <img class="slide-bg" src="images/top-rank-medical-university-of-russia-kazan-state-medical-university.jpg" />
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="aslider z-index">
                              
                                <h2 class="aslider--title mb-25" data-animation="fadeInUp" data-delay=".7s">KSMU-Listed in Top 5 Medical<br/> Universities of Russian Federation</h2>
                                <p class="aslider--subtitle mb-40" data-animation="fadeInUp" data-delay=".9s">Complete all faculties in English Medium; MBBS, Dentistry, Pharmacy.</p>
                                <div class="aslider--btn" data-animation="fadeInUp" data-delay=".9s">
                                <a href={process.env.PUBLIC_URL + '/apply-now'} class="theme-btn blacks-hover">Book your seat now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div></SwiperSlide>


            <SwiperSlide><div class="single-slider slider-height d-flex align-items-center swiper-slide" data-swiper-autoplay="5000">
            <img class="slide-bg" src="images/kazan-city-at-a-glance.jpg" />
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="aslider z-index">
                              
                                <h2 class="aslider--title mb-25" data-animation="fadeInUp" data-delay=".7s">Kazan is a beautiful city, well known <br/> for International Students</h2>
                                <p class="aslider--subtitle mb-40" data-animation="fadeInUp" data-delay=".9s">KSMU is situated in Kazan, recognized by WHO, NMC, ECFMG, GMC, BMDC, etc.</p>
                                <div class="aslider--btn" data-animation="fadeInUp" data-delay=".9s">
                                <a href={process.env.PUBLIC_URL + '/apply-now'} class="theme-btn blacks-hover">Book your seat now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div></SwiperSlide>
      </Swiper>
    </>

            )
        }
    }
export default Sliders;