import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import SideMenu from '../layout/SideMenu';
import Youtube from '../sliders/Youtube';


export class Faculties_fees extends Component {
    render() {

        return (
            <main>


                <div className="page-title__area pt-110 a1">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-12">
                                <div className="page__title-wrapper text-center">
                                    <h3 className="pb-100">Faculties & Fees </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <nav className="breadccrumb-bg">
                        <ul className="breadcrumb justify-content-center pt-20 pb-20">
                            <li className="bd-items"><a href={process.env.PUBLIC_URL + '/'}>Home</a></li>
                            <li className="bd-items bdritems">|</li>
                            <li className="bd-items"> <a href={process.env.PUBLIC_URL + '/about-ksmu'}> Faculties & Fees</a></li>
                        </ul>
                    </nav>
                </div>


                <div class="united-stats-area  ptb-100">
                    <div class="container">
                        <div class="row">

                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-sm-12">

                                <div class="sidebar__deatils">
                                    <div class="united-states">

                                        <p>Kazan State Medical University is one of the best medical universities in Russia. It is the main medical school in Tatarstan and provides doctors and nurses for the region with a population of more than three million people. Kazan Medical University is justifiably an International University. Nearly a thousand students from thirty countries all around the world are studying here and for half of them, the learning language is English. Moreover, we have a large number of students who came from neighboring and remote regions of the Russian Federation. We are proud that Kazan Medical University is attractive to young people from different parts of the world. Therefore our priority is to be supportive and careful to International students. KSMU is the third oldest Medical University in Russia after Moscow and St. Petersburg. The only university in Kazan is recognized by the Ministry of Healthcare & Ministry of Education and Science of the Russian Federation. KSMU is a Government-funded University. KSMU is recognized by leading medical councils viz. AMC, GMC, NMC, WHO and listed in the World Directory of Medical Schools (AVICENNA Directory for Medicine). The only university in Kazan, recognized by ECFMG (USA).</p>
                                        <h1>Faculties of Kazan State Medical University</h1>

                                        <ul class="necessary-link mb-40">
                                            <li><i class="fal fa-check-square"></i>General Medicine</li>
                                            <li><i class="fal fa-check-square"></i>Dentistry</li>
                                            <li><i class="fal fa-check-square"></i>Pharmacy</li>
                                            <li><i class="fal fa-check-square"></i>Pediatric</li>
                                            <li><i class="fal fa-check-square"></i>Medical Biochemistry</li>
                                            <li><i class="fal fa-check-square"></i>Preventive Medicine</li>
                                            <li><i class="fal fa-check-square"></i>Preparatory Faculty</li>
                                            <li><i class="fal fa-check-square"></i>Post Graduation</li>
                                        </ul>
                                        <h2>The medium of instruction is complete English Medium for International Students.</h2>
                                        <h4>Tuition fees for the 2024-25 Academic year</h4>
                                        <h5>Main Faculties for International Students are: Medicine, Dentistry, Pharmacy</h5>

                                        <table class="table" cellspacing="8" cellpadding="8">

                                            <tr>
                                                <th>Courses</th>
                                                <th>Duration</th>
                                                <th>Fee</th>

                                            </tr>

                                            <tr>
                                                <td>General Medicine (English Medium)</td>
                                                <td>6 years</td>
                                                <td>4,75,000 Rubles</td>
                                            </tr>

                                            <tr>
                                                <td>Pharmacy (English Medium)</td>
                                                <td>5 years</td>
                                                <td>2,77,000 Rubles</td>
                                            </tr>

                                            <tr>
                                                <td>Dentistry (English Medium)</td>
                                                <td>5 years</td>
                                                <td>4,95,100 Rubles</td>
                                            </tr>

                                        </table>
                                        <ul class="necessary-link mb-40">
                                            <li><i class="fal fa-check-square"></i>Hostel Fees are currently 18000-22000 RUB per year. Hostel fee typically increases by 5-7% per year according to the current prices for electricity, gas, water, heating, etc.</li>

                                            <li><i class="fal fa-check-square"></i>Medical Insurance fee is currently 6200 RUB per year. It can increase by 3-5% per year according to the annual rate of inflation.</li>

                                            <li><i class="fal fa-check-square"></i>Registration & Visa extension: 1600 RUB per year. </li>

                                        </ul>

                                        <h4>Note:</h4>
                                        <h5>* The tuition fee may increase from the 2nd – to the 6th year (6-12%).</h5>

                                        <h5>* The student has to pay for the RT-PSR report or any other medical tests in Russia if required.</h5>

                                        <h5>* As per the new rule of the Russian Federation from 29.12.21, everyone has to go through fingerprint and medical tests within 90 days of arrival to Russia, student has to pay for it.</h5>



                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="popular-country-2 pt-200 pb-120 youtube-bg">
                    <div className="container">
                        <div className="famous-countries">
                            <Youtube />
                        </div>
                    </div>
                </section>

            </main>




        )
    }

}

export default Faculties_fees;