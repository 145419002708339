import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import SideMenu from '../layout/SideMenu';
import Youtube from '../sliders/Youtube';


export class Become_a_partner_associate_for_ksmu extends Component {
    render() {

        return (
            <main>
                <Helmet>
                    <title>Become a Partner KSMU Kazan with Doctors-IEA Official Representative</title>
                    <meta name="description" content="Become a Partner Associate for KSMU Kazan with Doctors-IEA. The Official authorized Representative of Kazan state medical university russia." />
                    <link rel="canonical" href="https://studymbbsinkazan.com/become-a-partner-associate-for-ksmu/" />
                    <meta property="og:locale" content="en_US" />
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content="Become a Partner KSMU Kazan with Doctors-IEA Official Representative" />
                    <meta property="og:description" content="Become a Partner Associate for KSMU Kazan with Doctors-IEA. The Official authorized Representative of Kazan state medical university russia." />
                    <meta property="og:url" content="https://studymbbsinkazan.com/become-a-partner-associate-for-ksmu/" />
                    <meta property="og:site_name" content="Kazan State Med University" />
                    <meta property="article:modified_time" content="2024-01-11T06:20:36+00:00" />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:label1" content="Est. reading time" />
                    <meta name="twitter:data1" content="1 minute" />
                </Helmet>

                <div className="page-title__area pt-110 a1">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-12">
                                <div className="page__title-wrapper text-center">
                                    <h3 className="pb-100">Become a Partner Associate </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <nav className="breadccrumb-bg">
                        <ul className="breadcrumb justify-content-center pt-20 pb-20">
                            <li className="bd-items"><a href={process.env.PUBLIC_URL + '/'}>Home</a></li>
                            <li className="bd-items bdritems">|</li>
                            <li className="bd-items"> <a href={process.env.PUBLIC_URL + '/about_doctors_iea'}> Become a Partner Associate</a></li>
                        </ul>
                    </nav>
                </div>


                <div class="united-stats-area  ptb-100">
                    <div class="container">
                        <div class="row">

                            <div class="col-xxl-12 col-xl-12 col-lg-12 col-sm-12">

                                <div class="sidebar__deatils">
                                    <div class="united-states">
                                        <h2>Welcome to KSMU, Kazan</h2>

                                        <h1>Become a Partner Associate,<br /> Get best Services from Doctors-IEA</h1>

                                        <p>Doctors-IEA Pvt Ltd is the official authorized admission partner of Kazan State Medical University for Indians &amp; International students. Doctors-IEA having a direct contract with Kazan State Medical University for the last 10 years and having a great reputation among Students &amp; University Administration.</p>
                                        <p>For more information, you can call us at 1800-419-5827 (Toll-free) / +91-7428212236 (India), +7-9515190376  (Russia)</p>
                                        <h3>University Tie-up</h3>
                                        <p>Our Tie-up from the last 10 Yrs. More than 6 Programs in KSMU.</p>
                                        <h3>Comprehensive Services</h3>
                                        <p>Assistance at each step of the study abroad lifecycle; University selection, Selective Course details, application processes, admissions, fee structure, and visa processing. </p>
                                        <h3>Professional Advice</h3>
                                        <p>Increase your conversion rate through expert guidance from the Doctors-IEA team with many years of experience in the Study MBBS abroad in Russia.</p>


                                        <p>&nbsp;</p>

                                        <form id="ttm-quote-form" className="row ttm-quote-form clearfix" method="post" action="https://app.todesign.in/react/studymbbsinkazan.com/partner.php">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="single-form form-group">
                                                        <input name="name" type="text" class="contact__input" placeholder="Your name" data-error="Name is required." required="required" />

                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="single-form form-group">
                                                        <input name="email" type="email" class="contact__input" placeholder="Email" data-error="Valid email is required." required="required" />

                                                    </div>
                                                </div>

                                                <div class="col-md-6">
                                                    <div class="single-form form-group">
                                                        <input name="phone" type="text" class="contact__input" placeholder="Phone" data-error="Phone is required." required="required" />

                                                    </div>
                                                </div>

                                                <div class="col-md-6">
                                                    <div class="single-form form-group">
                                                        <input name="company" type="text" class="contact__input" placeholder="Company Name" data-error="Company Name is required." required="required" />

                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="single-form form-group">
                                                        <input name="website" type="utl" class="contact__input" placeholder="Company Website" data-error="Company Website is required." required="required" />

                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="single-form form-group">
                                                        <input name="city" type="text" class="contact__input" placeholder="City" data-error="City is required." required="required" />

                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="single-form form-group">
                                                        <input name="state" type="text" class="contact__input" placeholder="State" data-error="State is required." required="required" />

                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="single-form form-group">
                                                        <input name="country" type="text" class="contact__input" placeholder="Country" data-error="Country is required." required="required" />

                                                    </div>
                                                </div>

                                                <div class="col-md-12">
                                                    <div class="single-form form-group">
                                                        <textarea name="message" placeholder="Message" class="contact__input txt-area " data-error="Please,leave us a message." required="required"></textarea>

                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="single-form">
                                                        <button type="submit" name="submit" id="submit" class="theme-btn">Submit Now
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>



                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="popular-country-2 pt-200 pb-120 youtube-bg">
                    <div className="container">
                        <div className="famous-countries">
                            <Youtube />
                        </div>
                    </div>
                </section>

            </main>




        )
    }

}

export default Become_a_partner_associate_for_ksmu;