import React, { Component }  from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export class Clients extends Component {

    
    render() {
        var settings = {
            dots: false,
            infinite: false,
            speed: 500,
            autoplay: true,
            autoplaySpeed:3000,
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 0,
            arrows: false,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <div>
            <Slider {...settings}>
                <div className="testimonail__wrapper">
<div className="testimonail__wrapper__info d-flex align-items-center mb-25">
                        <div className="testimonail__wrapper__info__img ">
                            <img src="images/c1.jpg" className='img-fluid border-radius' alt="" />
                        </div>
                        <div className="testimonail__wrapper__info__author">
                            <h4>Mohit Kumar</h4>
                            <span>State: Gurgaon</span>
                        </div>
                        <div className="testimonail__wrapper__info__quotes">
                            <i className="flaticon-quote"></i>
                        </div>
                    </div>
                    <div className="testimonail__wrapper__content">
                        <p>Myself Mohit Kumar, studying at Kazan state medical university, Russia. I joined KSMU through Doctors-IEA,...</p>
                        <div className="testimonail__wrapper__content__reviews">
                            <ul>
                                <li><i className="fas fa-star"></i></li>
                                <li><i className="fas fa-star"></i></li>
                                <li><i className="fas fa-star"></i></li>
                                <li><i className="fas fa-star"></i></li>
                                <li><i className="fas fa-star"></i></li>
                           
                            </ul>
                        </div>
                    </div>
                </div>

              <div className="testimonail__wrapper">
<div className="testimonail__wrapper__info d-flex align-items-center mb-25">
                        <div className="testimonail__wrapper__info__img ">
                            <img src="images/c2.jpg" className='img-fluid border-radius' alt="" />
                        </div>
                        <div className="testimonail__wrapper__info__author">
                            <h4>Upendra Parmar</h4>
                            <span>State : Rajasthan</span>
                        </div>
                        <div className="testimonail__wrapper__info__quotes">
                            <i className="flaticon-quote"></i>
                        </div>
                    </div>
                    <div className="testimonail__wrapper__content">
                        <p>Hello everyone, I am Upendra from Rajasthan currently study at one of the best Medical...</p>
                        <div className="testimonail__wrapper__content__reviews">
                            <ul>
                                <li><i className="fas fa-star"></i></li>
                                <li><i className="fas fa-star"></i></li>
                                <li><i className="fas fa-star"></i></li>
                                <li><i className="fas fa-star"></i></li>
                                <li><i className="fas fa-star"></i></li>
                              
                            </ul>
                        </div>
                    </div>
                </div>


               <div className="testimonail__wrapper">
<div className="testimonail__wrapper__info d-flex align-items-center mb-25">
                        <div className="testimonail__wrapper__info__img ">
                            <img src="images/c3.jpg" className='img-fluid border-radius' alt="" />
                        </div>
                        <div className="testimonail__wrapper__info__author">
                            <h4>Dr. Anil Rajput</h4>
                            <span>State: Madhya Pradesh</span>
                        </div>
                        <div className="testimonail__wrapper__info__quotes">
                            <i className="flaticon-quote"></i>
                        </div>
                    </div>
                    <div className="testimonail__wrapper__content">
                        <p>Hi... I am Anil Rajput. I completed my MBBS studies at Kazan state medical university....</p>
                        <div className="testimonail__wrapper__content__reviews">
                            <ul>
                                <li><i className="fas fa-star"></i></li>
                                <li><i className="fas fa-star"></i></li>
                                <li><i className="fas fa-star"></i></li>
                                <li><i className="fas fa-star"></i></li>
                                <li><i className="fas fa-star"></i></li>
                            
                            </ul>
                        </div>
                    </div>
                </div>

              <div className="testimonail__wrapper">
<div className="testimonail__wrapper__info d-flex align-items-center mb-25">
                        <div className="testimonail__wrapper__info__img ">
                            <img src="images/c4.jpg" className='img-fluid border-radius' alt="" />
                        </div>
                        <div className="testimonail__wrapper__info__author">
                            <h4>Rishabh Sharma</h4>
                            <span>State: Chhattisgarh</span>
                        </div>
                        <div className="testimonail__wrapper__info__quotes">
                            <i className="flaticon-quote"></i>
                        </div>
                    </div>
                    <div className="testimonail__wrapper__content">
                        <p>Hi everyone!!! Myself Rishabh Sharma, a medical student at Kazan state medical university, Russia. I just...</p>
                        <div className="testimonail__wrapper__content__reviews">
                            <ul>
                                <li><i className="fas fa-star"></i></li>
                                <li><i className="fas fa-star"></i></li>
                                <li><i className="fas fa-star"></i></li>
                                <li><i className="fas fa-star"></i></li>
                                <li><i className="fas fa-star"></i></li>
                             
                            </ul>
                        </div>
                    </div>
                </div>

              <div className="testimonail__wrapper">
<div className="testimonail__wrapper__info d-flex align-items-center mb-25">
                        <div className="testimonail__wrapper__info__img ">
                            <img src="images/c5.png" className='img-fluid border-radius' alt="" />
                        </div>
                        <div className="testimonail__wrapper__info__author">
                            <h4>Fiaz Kidwai</h4>
                            <span>State: Uttar Pradesh</span>
                        </div>
                        <div className="testimonail__wrapper__info__quotes">
                            <i className="flaticon-quote"></i>
                        </div>
                    </div>
                    <div className="testimonail__wrapper__content">
                        <p>Hello everyone! I am currently studying at Kazan state medical university, Russia. I got admission...</p>
                        <div className="testimonail__wrapper__content__reviews">
                            <ul>
                                <li><i className="fas fa-star"></i></li>
                                <li><i className="fas fa-star"></i></li>
                                <li><i className="fas fa-star"></i></li>
                                <li><i className="fas fa-star"></i></li>
                                <li><i className="fas fa-star"></i></li>
                               
                            </ul>
                        </div>
                    </div>
                </div>


                </Slider>
            </div>
           
            )
        }
    }
export default Clients;