import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import SideMenu from '../layout/SideMenu';
import Youtube from '../sliders/Youtube';


export class Kazan_State_Medical_University extends Component {
    render() {

        return (       
           <main>
               

    <div className="page-title__area pt-110 a1">
    <div className="container">
        <div className="row">
           <div className="col-xxl-12">
              <div className="page__title-wrapper text-center">
                 <h3 className="pb-100">Kazan State Medical University </h3>
              </div>
           </div>
        </div>
     </div>
     <nav className="breadccrumb-bg">
        <ul className="breadcrumb justify-content-center pt-20 pb-20">
           <li className="bd-items"><a href={process.env.PUBLIC_URL + '/'}>Home</a></li>
           <li className="bd-items bdritems">|</li>
           <li className="bd-items"> <a href={process.env.PUBLIC_URL + '/kazan-state-medical-university'}> Kazan State Medical University</a></li>
        </ul>
     </nav>
</div>


<div class="united-stats-area  ptb-100">
    <div class="container">
        <div class="row">
       
        <div class="col-xxl-12 col-xl-12 col-lg-12 col-sm-12">

            <div class="sidebar__deatils">
                    <div class="united-states">
                <h1>Kazan State Medical University <br /> The Best University for MBBS in Russia</h1>
                   <p>As per the trend in the last decade or two, Russian Universities have emerged as the top destination for MBBS aspirants. However, not all universities are equally ranked when it comes to the standard and quality of education they impart to the students. Hence ticking the right option is imperative. As a medical aspirant, if you are looking for a reliable and leading medical university in Russia, then betting on <strong>Kazan Medical State University</strong> (KSMU) will be an excellent choice whatsoever. It is one of the trusted and oldest names in the history of universities in Russia. The number of Indian students who applied for <strong>MBBS in KSMU</strong> is quite phenomenal. Of course, there are several factors such as the opportunity to get exposure at the global level, searching assured medical career, fierce competition in India, and so on. When the number is so big, it also becomes quintessential that they have the right information and guidance about how to apply and take admission in KSMU. This is where <strong>Doctors-IEA (Doctors Ideal Educational Advisors)</strong> team will be of utmost importance because we are the official representative of <strong>Kazan State Medical University, Russia</strong>. We are in this field for years and know the ins and out of every step you require to get through in your journey to get admission in <strong>MBBS at KSMU</strong>. Undoubtedly, it can be the best university where you can achieve your goal at an affordable price without compromising the quality of medical education.</p>

                    </div>
                    </div>
                </div>
            </div>
            </div>
            </div>
            <section className="popular-country-2 pt-200 pb-120 youtube-bg">
    <div className="container">
	<div className="famous-countries">
		<Youtube />
		</div>
	</div>
</section>

           </main>
          



 )
}

}

export default Kazan_State_Medical_University;