import React, { Component } from 'react';
import Topheader from './Topheader';
import News from './News';
import { Menu } from './Menu';
import Logo from './Logo';
import Call from './Call';

export class Header extends Component {
  componentDidMount(){
    window.scroll(0,0)
}   
    
    render() {        
        return (
          <header>
          <div className='news'>
            <div className='container'>
            <News />
            </div>               
          </div>
     <div className="header-top">
    <div className="container">
<Topheader />
 </div>  
   </div> 
   <div className="header-menu header-sticky">
    <div className="container">
    <div className="row align-items-center">          
    <div className="col-xxl-9 col-xl-9 col-lg-9 col-12">
      <div className="main-menu  d-lg-block ">
      <Menu />
      </div>
    
      </div>
      <div className="col-xxl-3 col-xl-3 col-lg-3">
      <div className="main-menu-wrapper d-flex align-items-center justify-content-end">
      <Call />
      </div>
      </div>
    </div>
    </div>
    </div>
    
 </header>

            )
        }
    }
    
    export default Header;